import { createRouter, createWebHistory } from 'vue-router'

import TodayNotice from '../views/TodayNotice.vue'
import ConcernNotice from '../views/ConcernNotice.vue'
import NotixLogin from '../views/NotixLogin.vue'
import UserJoin from '../views/UserJoin.vue'
import RecommendNotix from '../views/RecommendNotix.vue'
import FindIdPwd from '../views/FindIdPwd.vue'
import ServiceTerm from '../views/ServiceTerm.vue'
import PrivateTerm from '../views/PrivateTerm.vue'
import LoadingOverlayView from '../views/LoadingOverlayView.vue'
import InfiniteLoadingView from '../views/InfiniteLoadingView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: TodayNotice
  },
  {
    path: '/today',
    name: 'today',
    component: TodayNotice
  },
  {
    path: '/concern',
    name: 'concern',
    component: ConcernNotice
  },
  {
    path: '/serviceTerm',
    name: 'ServiceTerm',
    component: ServiceTerm
  },
  {
    path: '/privateTerm',
    name: 'PrivateTerm',
    component: PrivateTerm
  },
  {
    path: '/join',
    name: 'UserJoin',
    component: UserJoin
  },
  {
    path: '/login',
    name: 'NotixLogin',
    component: NotixLogin
  },
  {
    path: '/recommend',
    name: 'RecommendNotix',
    component: RecommendNotix
  },
  {
    path: '/findIdPwd',
    name: 'FindIdPwd',
    component: FindIdPwd
  },
  
  {
    path: '/loading',
    name: 'LoadingOverlayView',
    component: LoadingOverlayView
  },
  {
    path: '/infinite',
    name: 'InfiniteLoadingView',
    component: InfiniteLoadingView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savePosition) { // 화면의 맨 앞으로 가기, 뒤로갈때는 원래 있던 위치로
    if(savePosition) {
        return savePosition
    } else {
        return { 
          top: 0,
          behavior: 'smooth',
         }
    }
},

})

export default router
