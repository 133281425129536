import axios from 'axios';

export default {
    methods:{
        async $api(url, data){
            return (await axios({
                method: 'post',
                url,
                data
            }).catch(e => {
                console.log(e);
            })).data;
        },
        async $verifyToken(headers_config){
            try {
                console.log("headers_config",headers_config);
                const res = await axios({
                    method:'post',url:'api/refresh', headers: headers_config});
                console.log("res",res);
                console.log("res.data.code",res.data.code);
                // accessToken 만료, refreshToken 정상 -> 재발급된 accessToken 저장 후 자동 로그인
                if (res.data.code == 200){
                    console.log("res.data.accessToken",res.data.data.accessToken);
                    console.log("res.data.refreshToken",res.data.data.refreshToken);
                    this.$store.commit("tokens",{
                        'accessToken': res.data.data.accessToken,
                        'refreshToken': res.data.data.refreshToken,
                        'user': res.data.data.user,
                       
                    });
                }  
                return res;
            
            } catch(error){
                console.log(error)
                return({
                    code: 500,
                    message: "system error",
                })
            }
        },
        $currencyFormat(value,format='#,###'){
            if (value == 0 || value == null) return 0;

            var currency = format.substring(0, 1);
            if (currency === '$' || currency === '₩') {
                format = format.substring(1, format.length);
            } else {
                currency = '';
            }

            var groupingSeparator = ",";
            var maxFractionDigits = 0;
            var decimalSeparator = ".";
            if (format.indexOf(".") == -1) {
                groupingSeparator = ",";
            } else {
                if (format.indexOf(",") < format.indexOf(".")) {
                groupingSeparator = ",";
                decimalSeparator = ".";
                maxFractionDigits = format.length - format.indexOf(".") - 1;
                } else {
                groupingSeparator = ".";
                decimalSeparator = ",";
                maxFractionDigits = format.length - format.indexOf(",") - 1;
                }
            }

            var prefix = "";
            var d = "";
            // v = String(parseFloat(value).toFixed(maxFractionDigits));

            var dec = 1;
            for (var i = 0; i < maxFractionDigits; i++) {
                dec = dec * 10;
            }

            var v = String(Math.round(parseFloat(value) * dec) / dec);

            if (v.indexOf("-") > -1) {
                prefix = "-";
                v = v.substring(1);
            }

            if (maxFractionDigits > 0 && format.substring(format.length - 1, format.length) == '0') {
                v = String(parseFloat(v).toFixed(maxFractionDigits));
            }

            if (maxFractionDigits > 0 && v.indexOf(".") > -1) {
                d = v.substring(v.indexOf("."));
                d = d.replace(".", decimalSeparator);
                v = v.substring(0, v.indexOf("."));
            }
            var regExp = /\D/g;
            v = v.replace(regExp, "");
            var r = /(\d+)(\d{3})/;
            while (r.test(v)) {
                v = v.replace(r, '$1' + groupingSeparator + '$2');
            }

            return prefix + currency + String(v) + String(d);
    
        }

    }
}