import {createStore} from 'vuex';
 
import persistedstate from 'vuex-persistedstate'  //세션유지

const store = createStore({
    state(){
        return {
            tokens:{},

        }
    },
    mutations:{

        tokens(state,data){
            state.tokens = data;
        },

    },
    plugins: [

        persistedstate({
            paths: ['tokens']
            
        }),

    ]

});

export default store;