<template>
    <!-- Modal -->
      <div class="modal fade" id="staticBackdropUserAgreement" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">서비스 이용약관</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <pre style="text-align:left; white-space: pre-wrap;  word-break: break-all;  overflow: auto;">
                총칙

제1조(목적)

이 약관은 '신나는 주식회사' (이하 “회사”라 합니다)가 전기통신 사업법령 및 정보통신망 이용 촉진 등에 관한 법령에 의하여 회사가 제공하는 모든 서비스 의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.

 

제2조(약관의 공지 및 준용)

① 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.

② 회사는 이 약관을 임의로 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.

 

제3조(약관 외 준칙)

이 약관에 명시되지 않은 사항은 전기통신 기본법 , 전기통신 사업법, 정보통신망 이용촉진등에 관한 법률 및 기타 관련법령의 규정에 의합니다.

 

제4조(용어의 정의)

이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

① 회원 : 회사와 서비스 이용계약을 체결하고 이용자 아이디를 부여받은 자.

② 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합

③ 비밀번호 : 회원의 비밀 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합

④ 운영자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람

⑤ 해지 : 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것

 

 

서비스 이용계약

제5조(이용계약의 성립)

① 회원으로 가입하는 것은 이 약관에 동의하는 것으로 간주됩니다.

② 이용계약은 회원의 이용신청에 대하여 회사가 심사 후 승낙함으로써 성립합니다.

 

제6조(회원정보 사용에 대한 동의)

① 회원의 개인 정보에 대해서는 회사의 개인정보 보호정책이 적용됩니다.

② 회사가 신청서에 기재를 요구하는 회원의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.

③ 회원이 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 공식 제휴약정을 맺은 제휴업체에 회원정보를 제공 및 공유할 수 있습니다.

④ 회원은 회원정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.

⑤ 회원이 이용신청서에 회원정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.

⑥ 회사에 본 약관에 따라 이용신청을 하는 것은 회원의 사용문의, 오류 상담시 회사에서 대리 로그인을 통해 해결할 수 있도록 동의한 것으로 간주합니다.

 

제7조(이용신청의 승낙)

① 회사는 회원이 모든 사항을 정확히 기재하여 이용신청을 하였을 때 제2호, 제3호의 경우를 예외로 하여 승낙할 수 있습니다.

② 회사는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 유보할 수 있습니다.

㉮ 설비에 여유가 없는 경우

㉯ 기술상 지장이 있는 경우

㉰ 기타 회사의 사정상 이용승낙이 곤란한 경우

③ 회사는 다음의 경우에 해당하는 이용신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.

㉮ 가입자명(상호 , 이름)이 실명이 아닌 경우

㉯ 다른 사람의 명의를 사용하여 신청한 경우

㉰ 이용신청시 필요내용을 허위로 기재하여 신청한 경우

㉱ 사회의 안녕 , 질서 또는 미풍양속을 저해할 목적으로 신청한 경우

㉲ 기타 회사가 정한 이용신청요건이 미비 되었을 때

 

제8조(계약사항의 변경)

회원은 이용 신청시 기재한 사항이 변경되었을 경우에는 온라인으로 수정을 해야 하고 미변경으로 인하여 발생되는 문제의 책임은 회원에게 있습니다.

 

 

계약당사자의 의무

제9조(회사의 의무)

① 회사는 서비스 제공과 관련해서 알고 있는 회원의 신상정보를 제 6조 3항의 절차 없이 제 3자에게 누설, 배포하지 않습니다. 단, 전기통신 기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그러하지 않습니다.

② 1항의 범위 내에서, 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계 자료를 작성하여 이를 사용할 수 있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우져의 설정을 변경할 수 있습니다.

 

제10조(회원의 의무)

① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.

㉮ 다른 회원의 ID를 부정하게 사용하는 행위 및 본인 ID를 제 3자에게 제공하는 행위

㉯ 서비스에서 얻은 정보를 회사의 사전승낙 없이 회원의 이용이외의 목적으로 복제하거나 이를 웹사이트에 게시,출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위

㉰ 회사의 저작권, 제3자의 저작권등 기타 권리를 침해하는 행위

㉱ 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위

㉲ 범죄와 결부된다고 객관적으로 판단되는 행위

㉳ 기타 관계법령에 위배되는 행위

② 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.

③ 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.

④ 회원은 회사의 사전 승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 회원이 약관에 위반한 영업활동을 이용하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동에 대하여 회사에 대하여 손해배상 의무를 집니다.

⑤ 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.

⑥ 회원은 회사에서 제공된 정보에 대하여 입찰에 관련된 행위를 하기 전에 해당 발주처에 입찰일자, 자격 및 금액등 입찰관련 내용을 직접 확인하여야 한다.

 

 

서비스이용

제11조(회원 아이디[ID]와 비밀번호 관리에 대한 회원의 의무)

① 1아이디는 1인 사용을 원칙으로 합니다.

② 아이디(ID)와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀 , 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.

③ 자신의 아이디(ID)가 부정하게 사용된 경우 회원은 반드시 회사에 그 사실을 통보해야 합니다.

 

제12조(정보의 제공)

회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 카카오 알림톡, SMS, LMS 메세지, 전자우편, 서신우편 등의 방법으로 회원에게 제공할 수 있으며 회원은 원치 않을 경우 정보수신거부를 할 수 있습니다.

 

제13조(게시물 관리)

회사는 회원이 게시하거나 등록하는 서비스내의 내용물이 다음 각 호의 1에 해당한다고 판단되는 경우에 사전통지없이 삭제할 수 있습니다.

① 당사 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우

② 공공질서 및 미풍양속에 위반되는 내용인 경우

③ 범죄적 행위에 결부된다고 인정되는 내용일 경우

④ 회사의 저작권, 제 3 자의 저작권 등 기타 권리를 침해하는 내용인 경우

⑤ 회사에서 규정한 게시기간을 초과한 경우

⑥ 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우

⑦ 기타 관계법령에 위반된다고 판단되는 경우

 

제14조(게시물의 저작권 귀속 및 이용제한)

서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.

① 게시물에 대한 권리와 책임은 게시자에게 있으며 회사는 게시자의 동의 없이는 이를 서비스내 게재 이외에 영리적 목적으로 사용하지 않습니다. 단, 비영리적인 경우에는 그러하지 아니하며 또한 회사는 서비스내의 게재권을 갖습니다.

② 회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙없이 회원의 서비스 이용이외의 목적으로 복제하거나 이를 변경, 송신, 출판, 배포 및 방송 기타 방법 등에 의하여 영리 목적으로 이용할 수 없으며, 회원사의 영리목적을 위한 제품 실현의 수단이 될 수 없습니다.

 

제15조(서비스 이용시간)

① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다.

② 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.

 

제16조(서비스 이용책임)

회원은 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 서비스를 이용하여 불법상품을 판매하는 영업활동을 할 수 없으며 특히 해킹 , 돈벌기 광고 , 음란사이트를 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치등에 관해서는 회사가 책임을 지지 않습니다.

 

제17조(서비스 제공의 중지)

① 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.

㉮ 서비스용 설비의 보수 등 공사로 인한 부득이한 경우

㉯ 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우

㉰ 기타 불가항력적 사유가 있는 경우

② 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 있습니다.

 

제18조(세금계산서 발행)

① 본 서비스의 이용요금에는 부가가치세가 포함된 금액으로써 회원이 입금한 이용료에 대하여 본 사이트 메인페이지에 회원이 쉽게 볼 수 있는 배너를 통해 세금계산서를 발행합니다.

② 웹사이트 내에 세금계산서를 발행함으로 세금계산서 발급에 대한 의무를 다 한 것으로 하며, e-mail 또는 우편으로 별도의 세금계산서를 발행하지 않습니다.

③ 회원은 웹사이트 내에 발행된 세금계산서를 언제나 확인, 인쇄하여 사용할 수 있습니다.

④ "신나는 주식회사"의 웹을 통해 발행된 세금계산서 서비스는 부가가치세법 제16조에 준하여 제공되며, 실제 세금계산서와 동일한 효력이 있습니다.

⑤ "신나는 주식회사"의 전자세금계산서는 동법령 상의 규정에 의거하여 그 발급 내용이 서버에 자동 저장되므로 위조, 변조, 기타 편집의 모든 책임은 이용자의 그 해당 업체에 있습니다.

 

 

계약해지 및 이용제한

제19조(계약해지 및 이용제한)

① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.

② 회사는 회원이 다음 각 호의 1에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.

가. 타인의 서비스 ID 및 비밀번호를 도용한 경우

나. 서비스의 정상적인 운영을 방해하거나 이를 시도한 경우

다. 가입한 이름이 실명이 아닌 경우

라. 같은 사용자가 다른 ID로 이중등록을 한 경우

마. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우

바. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우

사. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우

아. 다량의 정보전송 또는 광고성 정보전송 등의 형태로 서비스의 정상적인 운영에 영향을 주거나 이와 관련된 행위를 시도한 경우

자. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우

차. 회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우

카. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우

타. 타인의 개인정보, 이용자ID 및 비빌번호를 부정하게 사용하는 경우

파. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 경우

하. 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트 링크하는 경우

거. 본 약관을 포함하여 기타 회사가 정한 이용조건에 위반한 경우

③ 회사가 제공하는 서비스 운영정책을 준수하지 않거나 비합리적인 요청 등으로 회사의 업무를 방해하는 행위를 한 경우 기타 "회사"가 합리적인 판단에 기하여 "서비스"의 제공을 거부할 필요가 있다고 인정할 경우 회사는 서비스 이용을 제한하거나 계약을 해지시킬 수 있습니다.

④ 본 약관에 따라 계약이 해지된 경우 회원자격이 상실되며, 기 납부한 정보이용료는 환불되지 않습니다. 또한 회원 자격을 상실할 경우 회원으로서의 모든 혜택은 전부 소멸될 뿐 아니라 관련법에 의한 책임을 묻게 됩니다.

 

손해배상 및 이용료 환불 등

제20조(손해배상)

회사의 서비스 범위는 각지에 흩어져있는 정보를 이용하기 편리하게 한 곳에 모아 제공하는데 한 하며 본 서비스 이용과 관련하여 게시된 정보의 오류 및 누락 등에 관한 어떠한 손해에 관하여도 책임을 지지 않습니다.

※ 위 사항 외의 별도 해지 수수료는 없습니다.

 

제21조(이용료 환불)

① 이용료의 환불은 아래와 같이 계산되어 시행합니다.

  - 결제일로부터 1~10일 이내 사용	:  총 납입액-(1개월이용료 50%+결제대행수수료+송금수수료)

  - 결제일로부터 10일 이상 사용	  :  총 납입액-(1개월이용료*이용 월 수+결제대행수수료+송금수수료)

(결제수단에 따라 결제대행수수료는 상이할 수 있습니다.)

 

가. 이벤트 기간에 가입되어 추가 이용기간 및 사은품이 지급된 경우의 이용료 환불은 추가 이용기간에 따른 이용료와 사은품의 가격 및 배송료를 추가로 공제합니다.

② 이외 추가 서비스 이용료의 환불은 제21조 ①항의 기준에 의합니다.

※ 단, 제19조(계약해지 및 이용제한) ②항에 따른 계약해지시 사용기간에 관계없이 환불이 불가합니다.

※ 위 사항 외의 별도 해지 수수료는 없습니다.

 

제22조(책임제한)

① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.

② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.

③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.

④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.

 

제23조(관할법원)

요금 등 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.

 

[부칙]

본 약관은 2024년 12월 02일부터 시행합니다.

              </pre>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="staticBackdropPerson" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">개인정보 수집 및 이용에 대한 안내</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <pre style="text-align:left; white-space: pre-wrap;  word-break: break-all;  overflow: auto;">
신나는 주식회사는(이하 ‘회사’는) 고객님의 개인정보를 중요시하며, 개인정보 보호를 위해 다음과 같은 정책을 시행하고 있습니다. 본 정책은 ‘정보통신망 이용촉진 및 정보보호에 관한 법률’, ‘통신비밀보호법’, ‘개인정보보호법’, ‘위치정보의 보호 및 이용등에 관한 법률’ 등 정보통신 서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

 

1. 수집하는 개인정보의 항목 및 수집방법

1) 수집하는 개인정보의 항목

① 회사는 회원가입, 카카오 알림톡과 SMS 및 전자우편으로 정보 제공 등 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.

● 기업회원 가입시

- 필수항목 : 회사명, 업태, 종목, 회사전화번호, 팩스번호, 사업자등록번호, 회사주소, 대표자명, 로그인ID, 비밀번호

- 선택항목 : 세부종목, 담당자명, 이메일주소, 휴대폰번호, 우편물 수령 주소

● 개인회원 가입시

- 필수항목 : 로그인ID, 비밀번호, 이름, 전화번호

- 선택항목 : 회사명, 사업자등록번호, 대표자명, 회사주소, 업태, 종목, 세부종목, 담당자명, 이메일주소, 휴대폰번호, 우편물 수령 주소

② 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.

- IP Address, 쿠키, 방문 일시, OS종류, 브라우져 종류, 서비스 이용 기록, 불량 이용 기록,

- 모바일 서비스 이용 시 : 단말기 모델, 이동통신사 정보, 하드웨어ID, 단말기OS종류, 모델종류, 모바일 접속지역

③ 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이 수집될 수 있습니다.

- 개인정보 추가 수집에 대해 동의를 받는 경우

④ 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.

- 신용카드 결제시 : 카드사명, 카드번호 등

- 휴대전화 결제시 : 휴대전화번호, 통신사, 결제승인번호, 이메일 주소 등

- 계좌이체시 : 은행명, 계좌번호 등

- 상품권 이용시 : 상품권 번호

- 환불시 : 환불계좌정보(은행명, 계좌번호, 예금주명)

2) 개인정보 수집 방법

① 홈페이지(회원가입, 게시판), 서면양식을 통한 회원가입

② 회원정보수정, 제휴사로부터의 제공, 이벤트응모

③ 상담(전화, 이메일), 고객센터, Q&A, 배송요청, 비회원구매

④ 생성정보 수집 툴을 통한 수집

 

2. 개인정보의 수집 및 이용목적

회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전동의를 구할 것입니다.

1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산

① 컨텐츠 제공, 특정 맞춤 서비스 제공

② 물품배송 또는 청구서 등 발송

③ 금융거래 본인 인증 및 금융 서비스

④ 구매 및 요금 결제, 요금추심 등

2) 회원관리

① 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인 식별

② 불량회원의 부정 이용방지와 비인가 사용방지

③ 가입의사 확인, 가입 및 가입횟수 제한

④ 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부, 추후 법정 대리인 본인확인

⑤ 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달

3) 신규 서비스 개발 및 마케팅·광고에의 활용

① 신규 서비스 개발 및 맞춤 서비스 제공

② 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인

③ 이벤트 및 광고성 정보 제공 및 참여기회 제공

④ 접속빈도 파악 등에 대한 통계

 

3. 개인정보의 제공

수집된 개인정보는 아래의 내용에 따라 회사에 제공하게 됩니다. 이용자는 제공을 거부하실 수 있는 권리가 있으며, 거부 시에는 제공하는 혜택에 제한이 있을 수 있습니다.

1) 이용자들이 사전에 공개에 동의한 경우

- 회사 및 회사의 제휴사 이벤트 및 마케팅 정책에 의한 홍보를 위해 최소한의 기본정보 제공

2) 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우

3) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

 

4. 개인정보의 제3자 제공

회사는 개인정보를 "2. 개인정보 수집 및 이용목적"에서 고지한 범위내에서 사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

1) 입찰/평가위원 모집 정보 제공 서비스, 신청서 작성 서비스, 통계 분석 서비스 제공을 위한 운영 및 고객상담

- 개인정보를 제공받는 자 : 신나는 주식회사 

- 개인정보를 제공받는 자의 개인정보 이용 목적 : 입찰/평가위원 모집 정보 제공 서비스, 신청서 작성 서비스, 통계 분석 서비스 제공을 위한 운영 및 고객상담

- 제공하는 개인정보의 항목 : ID, 이름, 전화번호, 휴대전화번호, 주소, 이메일, 위원 모집 신청서 작성을 위해 고객이 제공한 모든 정보

- 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 회원 탈퇴시 까지


2) 정보주체가 사전에 동의한 경우

3) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

4) 제3자 제공 업무 발생 시, 필요시점에 고객에게 별도로 알리고 동의받는 절차가 진행됩니다.

 

5. 개인정보의 보유 및 이용기간

사용자 개인정보는 회사에서 제공하는 서비스를 이용하는 동안 계속적으로 보유하며 회사의 다양한 서비스 제공을 위하여 이용합니다. 사용자의 개인정보는 사용자가 직접 열람/정정이 가능하며, 가입해지를 요청한 경우에는 재가입 여부 판단을 목적으로 최소정보(아이디/고객명/사업자등록번호)만을 보존하며, 그 외 모든 정보는 재생이 불가한 형태로 영구 삭제됩니다. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

1) 회사 내부 방침에 의한 정보보유 사유

- 보존 항목 : 로그인ID, 상호명, 사업자등록번호, 대표이름(개인이름), 전화번호, 회사주소, 이메일주소

- 보존 근거 : 서비스 이용의 혼선 및 부정이용 방지, 불법적 사용자에 대한 관련 기관 수사협조

- 보존 기간 : 탈퇴 후 1개월

2) 관련법령에 의한 정보보유 사유

상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

① 계약 또는 청약철회 등에 관한 기록

- 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

② 대금결제 및 재화 등의 공급에 관한 기록

- 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

③ 소비자의 불만 또는 분쟁처리에 관한 기록

- 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

④ 본인 확인에 관한 기록

- 보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

⑤ 웹사이트 방문기록

- 보존 근거 : 통신비밀보호법

- 보존 기간 : 탈퇴 후 3개월

⑥ 개인위치정보에 관한 기록

- 보존 근거 : 위치정보의 보호 및 이용 등에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

 

6. 개인정보의 파기절차 및 방법

이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.

1) 파기절차

① 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 보관장소) 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.

② 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

③ 보존기간이 경과한 정보 관련 문서(대외비는 부서보안관리자, 비밀은 정보보호관리자)는 해당관리자의 승인을 받아 폐기합니다.

2) 파기방법

① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법(재사용 시 덮어 쓰기, 로우(low) 포맷 등)을 사용하여 삭제합니다.

 

7. 이용자 및 법정대리인의 권리와 그 행사방법

1) 이용자 및 법정대리인은 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.

① 개인정보 열람요구

② 오류 등이 있을 경우 정정 요구

③ 삭제요구

④ 처리정지 요구

2) 제1항에 따른 권리 행사는 회사 고객센터 내 개인정보(열람, 정정·삭제, 처리정지) 요구서에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.

3) 이용자 및 법정대리인이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.

4) 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.

이 경우 위임장을 작성하여 제출하셔야 합니다.

5) 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

 

8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항

회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.

 

1) 쿠키의 사용 목적

회사는 회원인증을 위하여 쿠키(Cookie)방식을 이용하고 있습니다. 이는 로그아웃(Logout)시 자동으로 컴퓨터에 저장되지 않고 삭제되도록 되어 있으므로, 공공장소나 타인이 사용할 수 있는 컴퓨터를 사용하실 경우에는 로그인(Login)후 서비스 이용이 끝나시면 반드시 로그아웃(Logout)해 주시기 바랍니다.

2) 쿠키의 설치·운영 및 거부

① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.

② 설정방법 예(인터넷 익스플로어 경우 : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보)

③ 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.

 

9. 개인정보의 기술적/관리적 보호 대책

회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1) 비밀번호 암호화

회원아이디(ID)의 비밀번호는 암호화 되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2) 해킹 등에 대비한 대책

회사는 서버 및 네트워크 관련 첨단 보안시스템을 갖추고 있습니다. 자체 방화벽 및 침입탐지 시스템, 침입방지 시스템을 갖추고 고객님의 개인정보 보호를 위해 최선의 방법을 취하고 있습니다. 또한 전자 결제에 대해서는 SSL(Secure Socket Layer)방식으로 보안 시스템을 탑재하고 있습니다. 또한 고객님이 개인정보를 열람할 수 있는 시스템에는 2차 암호 체제를 갖추고 외부 네트워크로부터 철저하게 격리시켜 외부 침입 및 내부 침입에 대응하고 있습니다.

3) 취급 직원의 최소화 및 교육

회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보취급방침의 준수를 항상 강조하고 있습니다.

4) 개인정보보호전담기구의 운영

사내 개인정보보호전담기구 등을 통하여 회사의 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

 

10. 개인정보보호최고책임자 및 담당자의 연락처

귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호최고책임자 혹은 담당부서로 신고하실 수 있습니다.

회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

- 개인정보보호최고책임자 : 김연식 대표, 1577-7338, kim@sinnaneun.co.kr

- 개인정보보호관리자 : 송칠용 COO, 1577-7338, cysong3@sinnaneun.co.kr


기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

- 개인정보침해신고센터 ( http://www.1336.or.kr / (국번없이) 1336 )

- 개인정보분쟁조정위원회 ( http:// http://www.kopico.or.kr / 02-405-5150 )

- 대검찰청 사이버범죄수사단 ( http://cybercid.spo.go.kr / 02-3480-2480 )

- 경찰청 사이버테러대응센터 ( http://www.ctrc.go.kr / 02-3150-2659 )

 

11. 고지의 의무

현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.

- 개인정보 취급방침 시행일자: 2024.12.02.

              </pre>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div>
      </div>

      
      <div class="container py-4">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-xl-4 col-lg-6">
            <div class="card rounded-3 text-black shadow-sm">
              <div class="row g-0">
                <div class="col-lg-12">
                  <div class="card-body p-md-1 mx-md-4 " >
                    <div class="text-center mt-3 mb-1 pb-1">
                      <img src="@/assets/images/notix_log_login.png">
                      <h2 class="mt-2 mb-1 pb-1 fw-semibold">회원가입</h2>
                    </div>
                    <form>
                      <div class="mb-4">
                        <label for="nameInput" class="form-label d-flex justify-content-start">이름</label>
                        <input v-model="name" :class="{ 'input-danger': nameHasError }" type="text" class="form-control form-control-lg shadow-sm" ref="nameInput" placeholder="이름을 입력해주세요(2~10자이내)">
                        <p v-show="valid.name"  class="input-error"> 이름을 입력해주세요(2~10자이내). </p>
                      </div>
                      <div class="mb-4">
                        <label for="emailInput" class="form-label d-flex justify-content-start">아이디(EAMIL주소)</label>
                        <input v-model="email" :class="{ 'input-danger': emailHasError }" type="email" class="form-control form-control-lg shadow-sm" ref="emailInput" placeholder="이메일 주소를 정확히 입력해주세요">
                        <p v-show="valid.email"  class="input-error"> 이메일 주소를 정확히 입력해주세요(30자이내). </p>
                      </div>
                      <div class="mb-4">
                        <label for="passwordInput" class="form-label d-flex justify-content-start">패스워드</label>
                        <input v-model="password" :class="{ 'input-danger': passwordHasError }" type="password" class="form-control form-control-lg shadow-sm" ref="passwordInput" placeholder="영문,숫자,특수문자 포함 8~16자">
                        <p v-show="valid.password"  class="input-error"> 패스워드는 영문,숫자,특수문자 포함 8~16자입니다. </p>
                      </div>
                      <div class="mb-4">
                        <label for="passwordConfirm" class="form-label d-flex justify-content-start">패스워드 확인</label>
                        <input v-model="passwordConfirm" :class="{ 'input-danger': passwordConfirmHasError }" type="password" class="form-control form-control-lg shadow-sm" ref="passwordConfirm" placeholder="패스워드를 다시 입력해 주세요">
                        <p v-show="valid.passwordConfirm"  class="input-error"> 패스워드를 다시 입력하시기 바랍니다.. </p>
                      </div>
                      
                      <div class="mb-3">
                        <label for="phoneNumInput" class="form-label d-flex justify-content-start">휴대폰 번호 인증</label>
                        <div class="input-group mb-3 ">
                          <input v-model="phoneNum" :class="{ 'input-danger': phoneNumHasError }" v-bind:disabled="phoneNumConfirm ==true"
                          type="text" class="form-control form-control-lg shadow-sm" ref="phoneNumInput" placeholder="000-0000-0000">
                          <button class="btn btn-lg btn-secondary shadow-sm" v-bind:disabled="phoneCodeSendCount > 3 || phoneNumConfirm ==true"
                          type="button" id="button-addon2" @click="createPhoneCode();" >인증번호받기</button>
                          <p v-show="valid.phoneNum"  class="input-error"> 휴대폰 번호(000-0000-0000)를 입력하시기 바랍니다.. </p>
                        </div>
                      </div>
                      <div class="mb-4">
                        <input type="text" v-model="phoneCodeReceive" :class="{ 'input-danger': phoneNumConfirmHasError }" 
                        class="form-control  form-control-lg shadow-sm" ref="phoneNumConfirm" placeholder="인증번호 6자리" v-bind:disabled="phoneNumConfirm ==true">
                        <p v-show="valid.phoneNumConfirm"  class="input-error"> 휴대폰으로 받은 인증번호 6자리를 입력하시기 바랍니다.. </p>
                      </div>
                    
                      <div class="d-flex justify-content-end align-items-center pb-3">
                        <label class="form-check-label me-2" >
                            Noti-X 서비스 이용 약관
                        </label>

                        <button  type="button" class="btn btn-sm btn-outline-secondary shadow-sm"
                          data-bs-toggle="modal" data-bs-target="#staticBackdropUserAgreement">내용보기</button>
                      </div>
                      <div class="d-flex justify-content-end align-items-center pb-3">
                        <label class="form-check-label me-2" >
                            개인 정보 수집 및 이용에 대한 안내
                        </label>

                        <button  type="button" class="btn btn-sm btn-outline-secondary shadow-sm"
                          data-bs-toggle="modal" data-bs-target="#staticBackdropPerson">내용보기</button>
                      </div>
                        <div class="form-check d-flex justify-content-center">
                        <input v-model="termsConfirm" :class="{ 'input-danger': termsConfirmHasError }" class="form-check-input me-2 shadow-sm" type="checkbox" value="" id="flexCheckChecked" checked>
                        
                        <label class="form-check-label" for="flexCheckChecked" >
                            위 약관에 모두 동의 합니다.
                        </label>
                        </div>
                        <p v-show="valid.termsConfirm"  class="input-error">약관에 동의하셔야 합니다. </p>
                      <div class="text-center pt-4 mb-4 pb-1">
                        <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold shadow-sm" type="button" @keyup.enter="userJoinConfirm" @click="userJoinConfirm">회원가입</button>
                      </div>
    
                    </form>
    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </template>
    <style scoped>
    .greeting[data-v-7ba5bd90] {
      color: red;
      font-weight: bold;
    }
    .input-error {
    line-height: 16px;
    font-size: 13px;
    color:  red;
    }
    .title-danger {
      color:  red;
    }
    .input-danger {
      border: 1px solid  red !important;
    }

    </style>
    <script>

    export default{ 
    
        components : {
        },
        data(){
            return{
              valid:{
                name:false,
                email: false,
                password: false,
                passwordConfirm: false,
                phoneNum:false,
                phoneNumConfirm:false,
                termsConfirm:false
              },
              nameHasError: false,
              emailHasError: false,
              passwordHasError: false,
              passwordConfirmHasError: false,
              phoneNumHasError: false,
              phoneNumConfirmHasError: false,
              termsConfirmHasError: false,

              name:'',
              email:'',
              password:'',
              passwordConfirm:'',
              phoneNum:'',

              userJoinUpdate: false,

              phoneCodeSend: false,
              phoneCodeSendCount: 0,
              phoneCodeReceive:'',
              phoneNumConfirm : false,
              termsConfirm:true,

            };
        },
        setup(){},
        created(){
          this.phoneNum = ''
        },
        mounted(){
          
        },
        watch:{
          'name': function() {
            this.checkName()
          },
          'email': function() {
            this.checkEmail()
          },
          'password': function() {
            this.checkPassword()
          },
          'passwordConfirm': function() {
            this.checkPasswordConfirm()
          },
          'phoneNum': function() {
            this.checkPhoneNum()
          },
          'termsConfirm': function() {
            this.checkTermsConfirm()
          },
          'phoneCodeReceive': function() {
            this.checkPhoneNumConfirm()
          },
        },
        computed:{

        },
        unmounted(){},
        methods:{
          checkName() {
            // 이메일 형식 검사
            const validateName = /^[A-Za-z가-힣]+/
              if (!validateName.test(this.name) || !this.name || this.name.length < 2 ) {
                this.valid.name = true
                this.nameHasError = true
                return
              }else{
                this.valid.name = false
                this.nameHasError = false
                return
              }  
            
          },
          async checkEmail() {
            // 이메일 형식 검사
            const validateEmail = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/

              if (!validateEmail.test(this.email) || !this.email || !(this.email.length < 30)) {
                this.valid.email = true
                this.emailHasError = true
                return
              }else{
                let userData = await this.$api("/api/checkLoginId",{param:[this.email]});
                if ('error' in userData){
                  this.$swal.fire({
                      title:'서버 오류 ',
                      html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
                      icon: 'error',
                      confirmButtonText: '확인'
                    });
                    return
                }

                if (userData.length == 1 ){
                  console.log(userData[0].user_name,userData[0].login_type);
                  if(userData[0].login_type != null){
                    this.$swal.fire({
                      title:'회원가입 오류 ',
                      html: '회원가입된 이메일 아이디입니다.<br/>다른 이메일 아이디를 입력하시기 바랍니다.',
                      icon: 'error',
                      confirmButtonText: '확인'
                    });
                    this.email = '';
                    return
                  }else{
                    //기존 베타 서비스 사용자
                    this.userJoinUpdate = true;
                  }
                }else{  
                  this.valid.email = false
                  this.emailHasError = false
                  return
                }
              }
          
            
          },
          checkPassword() {
            // 비밀번호 형식 검사(영문, 숫자, 특수문자)
            const validatePassword = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/

              if (!validatePassword.test(this.password) || !this.password) {
                this.valid.password = true
                this.passwordHasError = true
                return
              }else{
                this.valid.password = false
                this.passwordHasError = false
                return
              }
           
          },
          checkPasswordConfirm() {
        

              if (!(this.password== this.passwordConfirm)) {
                this.valid.passwordConfirm = true
                this.passwordConfirmHasError = true
                return
              }else{
                this.valid.passwordConfirm = false
                this.passwordConfirmHasError = false
                return
              }
       
            
          },
          checkPhoneNum() {
            // 비밀번호 형식 검사(영문, 숫자, 특수문자)
            const validatePhoneNum =/^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

            if (!validatePhoneNum.test(this.phoneNum) || !this.phoneNum) {
              this.valid.phoneNum = true
              this.phoneNumHasError = true
              return
            }else{
              this.valid.phoneNum = false
              this.phoneNumHasError = false
              return
            }
          },
          async createPhoneCode(){

            if (this.phoneNum.length == 0){
              this.$swal.fire({
                title:'Warnning',
                text: '휴대폰 번호을 입력하시기 바랍니다..',
                icon: 'error',
                confirmButtonText: '확인'
              })
            } else if (this.phoneNumHasError){
              this.$swal.fire({
                title:'Warnning',
                text: '휴대폰 번호을 정확히 입력하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              })
            }else{
              let userData = await this.$api("/api/checkPhonNum",{param:[this.phoneNum.replace(/[^0-9]/g, "")]});
              // console.log("userData",userData);
              // console.log("userData",userData.length);
              // console.log("'error' in userData",'error' in userData);
              if ('error' in userData){
                this.$swal.fire({
                    title:'서버 오류 ',
                    html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
              }
              if (userData.length == 1 ){
                console.log(userData[0].user_name,userData[0].login_type);
                if(userData[0].login_type != null){
                  this.$swal.fire({
                    title:'회원가입 오류 ',
                    html: '등록된 휴대폰 번호입니다.<br/>관리자에게 문의하시기 바랍니다',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
                }else{
                  //기존 베타 서비스 사용자
                  this.userJoinUpdate = true;
                }

              }
              if (this.phoneCodeSendCount > 3){
                this.$swal.fire({
                    title:'인증번호발송 횟수 오류',
                    html: '인증번호를 3회 발송했습니다.<br/>5분후에 다시 접속하시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
              }else{
                console.log("phone number",this.phoneNum.replace(/[^0-9]/g, ""));
                let res = await this.$api("/api/createPhoneCode",{param:[this.phoneNum.replace(/[^0-9]/g, "")]});
                console.log("res",res);
                if(res == 'ok'){
                  this.phoneCodeSend = true;
                  this.phoneCodeSendCount = this.phoneCodeSendCount+1;
                  this.$swal.fire({
                    title:'인증번호발송 완료('+this.phoneCodeSendCount+'회)',
                    html: 'SMS메세지를 확인하시고<br/>3분이내 회원가입 바랍니다.',
                    icon: 'success',
                    confirmButtonText: '확인'
                  });
                }else{
                  this.$swal.fire({
                    title:'인증번호발송 오류',
                    html: '관리자에게 확인 바랍니다.(010-5119-2820)',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                }
              }
              
            }

          },
          async checkPhoneNumConfirm() {
            // 비밀번호 형식 검사(영문, 숫자, 특수문자)
            if(!this.phoneCodeSend){ 
              this.valid.phoneNumConfirm = true
              this.phoneNumConfirmHasError = true
              return
            }else if (this.phoneCodeReceive.length == 6){
              let validatePhoneNumConfirm = await this.$api("/api/getPhoneCode",{param:[this.phoneNum.replace(/[^0-9]/g, "")]});
              console.log("validatePhoneNumConfirm",validatePhoneNumConfirm);
              if (validatePhoneNumConfirm != this.phoneCodeReceive) {
                this.valid.phoneNumConfirm = true
                this.phoneNumConfirmHasError = true
                return
              }else{
                this.valid.phoneNumConfirm = false
                this.phoneNumConfirmHasError = false
                this.phoneNumConfirm = true
                this.$swal.fire({
                    title:'휴대폰 번호 확인 완료',
                    html: '본인 휴대폰 확인이 완료 되었습니다.',
                    icon: 'success',
                    confirmButtonText: '확인'
                  });
              }

            }else{
              this.valid.phoneNumConfirm = true
              this.phoneNumConfirmHasError = true
            }
          },
          checkTermsConfirm() {
            // 비밀번호 형식 검사(영문, 숫자, 특수문자)
            //const termsConfirm = True

              if (!this.termsConfirm) {
                this.valid.termsConfirm = true
                this.termsConfirmHasError = true
                return
              }else{
                this.valid.termsConfirm = false
                this.termsConfirmHasError = false
                return
              }

          },
          async userJoinConfirm(){
            
            if ( this.name == '' || this.name.length < 2 || this.nameHasError) {
              this.$refs.nameInput.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '이름을 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }
            if ( this.email.length < 3 || this.emailHasError) {
              this.$refs.emailInput.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '이메일 아이디을 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            if ( this.password.length < 8 || this.passwordHasError) {
              this.$refs.passwordInput.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '패스워드를 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            if ( this.passwordConfirm.length < 8 || this.passwordConfirmHasError) {
              this.$refs.passwordConfirm.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '패스워드를 다시 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            if ( this.phoneNum.length < 5 || this.phoneNumHasError) {
              this.$refs.phoneNumInput.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '휴대폰 번호를 다시 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            if ( this.phoneCodeReceive.length !=6 || this.phoneNumConfirmHasError) {
              this.$refs.phoneNumConfirm.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '휴대폰 인증 번호 확인를 하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            if ( !this.termsConfirm) {
              //this.$refs.termsConfirm.focus();
              this.$swal.fire({
                title:'회원가입 오류',
                html: '약관에 동의 하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            let confirm = ''
            let user ={user_name:this.name,login_id:this.email,email:this.email,login_pass:this.password,phone_num:this.phoneNum.replace(/[^0-9]/g, ""),login_type:'1',user_type:'1'}
            if(this.userJoinUpdate){
              confirm = await this.$api("/api/userjoinUpdate",{param:[user]});
            }else{
              
              confirm = await this.$api("/api/userJoin",{param:[user]});
            }

            if ('error' in confirm){
                console.log('error',confirm)
                this.$swal.fire({
                    title:'서버 오류 ',
                    html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
            }else{
              this.$swal.fire({
                title: '회원가입완료',
                html: '로그인 페이지로 이동합니다.',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(async (result) => {
                console.log(result);
                this.$router.push({path:'/login'});

              });
            } 
          }

        }
    }
    </script>