<template>
    <main class="mt-3">
      <div class="container">
        <div class="row mb-2">
          <vue-loading
            v-model:active="isLoading"
            :can-cancel="true"
            :is-full-page="fullPage"
          />
          <div class="col-4">
            <select class="form-select" v-model="gubun" @change="changeGubun" >
              <option :value="cate.name" :key=i v-for="(cate,i) in gubunList">{{cate.name}}</option>
            </select>
          </div>
          <div class="col-4 px-0">
            <select class="form-select" v-model="concern" @change="changeConcern" >
              <option :value="cate.name" :key=i v-for="(cate,i) in concernList">{{cate.name}}</option>
            </select>
          </div>
          <div class="col-4">
            <select class="form-select" v-model="category" @change="changeCategory" >
              <option :value="cate.name" :key=i v-for="(cate,i) in userConcernNoticeClassCountList">{{cate.name+'('+cate.count+')'}}</option>
            </select>
          </div>
        </div>
        <div v-if="displayNoticeList.length > 0" class="row g-3">
          <template :key="i" v-for="(notice,i) in displayNoticeList" >
            <div class="col-xl-4 col-lg-6 col-md-12">
              <div class="card w-80" >
                <div class="card-body border shadow-sm border-light">
                  <span v-if="getNoticeCount(notice.id) ==0"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">{{getNoticeCount(notice.id)}}</span>
                  <span v-else-if="getNoticeCount(notice.id) > 0 && getNoticeCount(notice.id) <=3"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{{getNoticeCount(notice.id)}}</span>
                  <span v-else-if="getNoticeCount(notice.id) > 3 && getNoticeCount(notice.id) <10"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">{{getNoticeCount(notice.id)}}</span>
                  <span v-else  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{getNoticeCount(notice.id)}}</span>
                  <a @click="goToDetail(notice.id);" style="cursor:pointer;">
                    <h5 class="card-text text-start text-wrap lh-base fw-semibold"  style="height: 6rem;">{{notice.title}}</h5>
                  </a>
                  <p class="card-text text-start text-secondary " style="height: 2rem;">{{ notice.keyword }}</p>
                  <div class="d-flex justify-content-between align-items-center" >
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-md btn-primary btn-block text-white fw-semibold me-2"  @click="goToSite(notice.id);">공고 바로가기</button>
                      <button type="button" class="btn btn-md btn-secondary btn-block text-white fw-semibold"  @click="deleteConcern(notice.id);">삭제</button>
                    </div>
                    <small v-if="notice.notice_reg==notice.period_end" class="text-dark"></small>
                    <small v-else class="text-dark">접수마감 : {{notice.period_end}}</small>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="row g-3">
          <div class="col-12">
            <div class="card w-80" >
              <div class="card-body border shadow-sm 'border-info">
                <h6 class="card-text text-center text-wrap lh-base fw-semibold"  style="height: 6rem;">{{dateString}}일 현재까지 수집된 정보가 없습니다. <br>1~2시간에 한번씩 데이터를 수집해서 제공합니다.
                <br>다른 날짜로 변경하시거나 <br> 잠시 후 다시 접속해 주시기 바랍니다.</h6>
              </div>

            </div>
          </div>
        </div>
        
        <div v-if="this.tokens.user == undefined && concernNoticeCount > 0" class="row g-3">
          <div class="text-center pt-4 mb-4 pb-1">
                        <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold shadow-sm" type="button" @click="userLogin">더보기({{concernNoticeCount-3}}건)</button>
                      </div>
        </div>
      </div>
    </main>
    </template>
    <script>
      import dayJs from 'dayjs'
      export default {
        data(){
          return {
            noticeTotalClickCountList:[],
            userConcernNoticeClassCountList:[],
            userConcernNoticeList:[],
            displayNoticeClickCountList:[],

            totalDisplayList:[],
            displayNoticeList : [],
            gubunList:[],
            category:"전체",
            noticeClassId:999,
            gubun:'위원모집',
            concernList:[],
            concern:'최근목록',
            date:new Date(),
            concernNoticeCount: 0,
            isLoading: false,
            fullPage: true
           
          };
        },
        created(){


        },
        watch:{
          'date': function() {
            this.changeDate();
          },
        },
        computed: {

          tokens(){{
            return this.$store.state.tokens;
          }},

           dateString(){{
            return this.getDateFormat()
           }}
        },
        mounted(){
          this.verifyTokenStatus();
          this.getGubunList();
          this.getConcernList()
          this.getconcernNoticeList();
        },
        methods:{
          userLogin(){
            this.$swal.fire({
                title: '로그인',
                html: '로그인/회원가입 후 이용하실 수 있습니다.',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(async (result) => {
                console.log(result);
                this.$router.push({path:'/login'});

              });
          },
          getDateFormat(){
            return dayJs(this.date).format("MM/DD/YYYY")
          },
          isWeekEnd(){
            let weekend = dayJs(this.date).day();
            if (weekend == 0 || weekend == 6){
              return true;
            }else{
              return false;
            }
          },
          async verifyTokenStatus(){
            console.log("this.tokens",this.tokens)
            if(this.tokens.user == undefined) {
              // this.$swal.fire({
              //   title:'로그인',
              //   text: '로그인을 하셔야 서비스를 이용하실 수 있습니다.',
              //   icon: 'error',
              //   confirmButtonText: '확인'
              // });
              // this.$router.push({path:'/'});
              console.log("this.tokens.user == undefined")
            }else if (this.tokens.user.login_type == 1){
              const headers_config = {
                "login_id": this.tokens.user.login_id,
                "refresh": this.tokens.refreshToken,
                Authorization: `Bearer ${this.tokens.accessToken}`   
              };
              const res = await this.$verifyToken(headers_config);
              console.log("verifyToken",res)
              
              if(res.data.code == 500){
                this.$swal.fire({
                  title:'시스템 오류',
                  text: '시스템에 접속할 수 없습니다. 관리자에게 문의해 주시기 바랍니다..',
                  icon: 'error',
                  confirmButtonText: '확인'
                });
              }else if(res.data.code == 401){
                console.log("res.code",res.code)
                this.$swal.fire({
                  title:'세션만료',
                  text: '다시 로그인 하시기 바랍니다.',
                  icon: 'error',
                  confirmButtonText: '확인'
                });
                this.$router.push({path:'/login'});
              }
            }
          },
          getGubunList(){
              if( this.tokens.user !=undefined && this.tokens.user.alert_type == 7){
                this.gubunList[0]={name:'위원모집',code:'1'}
                this.gubunList[1]={name:'입찰공고',code:'2'}
              }else{
                this.gubunList[0]={name:'위원모집',code:'1'}
              }

          },
          changeGubun(){
            console.log("this.gubun",this.gubun);
            if(this.gubun == '위원모집'){
              //매번 가져오게 할건지 아니면 메모리 job으로 할건지..
              this.getconcernNoticeList()
              //this.displayNoticeList = this.totalDisplayList;
            }else{
              console.log("this.gubun",this.gubun);
              //this.getTodayBidList()
            }
          },
          getConcernList(){
              
            this.concernList[0]={name:'최근목록',code:'1'}
            this.concernList[1]={name:'마감임박',code:'2'}
            this.concernList[2]={name:'신청마감',code:'3'}
            //this.concernList[1]={name:'신청목록',code:'4'}


          },
          changeConcern(){
            console.log("this.concern",this.concern);
            this.category = "전체";
            this.getconcernNoticeList()

          },
          changeDate(){
            if( this.tokens.user !=undefined){
              this.getconcernNoticeList();
            }else{
              this.$swal.fire({
                  title: '로그인',
                  html: '로그인 / 회원가입 후 이용하실 수 있습니다.',
                  icon: 'success',
                  confirmButtonText: '확인'
                }).then(async (result) => {
                  console.log(result);
                  this.$router.push({path:'/login'});

                });
            }
          },
          getNoticeCount(notice_id){
            //console.log("notice_id",notice_id)
            if (this.displayNoticeClickCountList[notice_id] == undefined){
              return 0;
            }else{
              return this.displayNoticeClickCountList[notice_id].click_count;
            }
          },
          async getconcernNoticeList(){
            this.isLoading = true
           
            //console.log("this.getDateFormat()",this.getDateFormat());
            //console.log("this.$store",this.$store);
            //console.log("this.tokens",this.tokens);

            if(this.tokens.user == undefined) {
              this.$swal.fire({
                  title: '로그인',
                  html: '로그인 / 회원가입 후 이용하실 수 있습니다.',
                  icon: 'success',
                  confirmButtonText: '확인'
                }).then(async (result) => {
                  console.log(result);
                  this.$router.push({path:'/login'});

                });
            }else{
              //console.log("this.tokens.user.alert_type",this.tokens.user.alert_type);
              //console.log("this.tokens.user.user_id",this.tokens.user.user_id);
              //let res = await this.$api("/api/userConcernNotice",{param:[this.tokens.user.id,this.gubun,this.concern,this.category,this.noticeClassId]});
              let res = await this.$api("/api/userConcernNotice",{param:[this.tokens.user.id,this.gubun,this.concern,"전체",this.noticeClassId]});
              console.log("res",res);

              this.noticeTotalClickCountList= res.data.noticeTotalClickCountList;
              this.userConcernNoticeClassCountList= res.data.userConcernNoticeClassCountList;
              this.userConcernNoticeList= res.data.userConcernNoticeList;

            }
            //console.log("this.noticeTotalClickCountList",this.noticeTotalClickCountList);
            //console.log("this.userConcernNoticeClassCountList",this.userConcernNoticeClassCountList);
            //console.log("this.userConcernNoticeList",this.userConcernNoticeList);
            //this.concernNoticeList = concernNoticeList;
            //console.log("concernNoticeList",concernNoticeList);

            //console.log("this.concernMyNoticeCountList",this.concernMyNoticeCountList);
            if(this.noticeTotalClickCountList.length > 0){
              this.noticeTotalClickCountList.forEach(item => {
                this.displayNoticeClickCountList[item.notice_id] = {click_count:item.click_count};

              });
              //console.log("this.displayNoticeClickCountList",this.displayNoticeClickCountList);
            }
            let oCategory = {};
            //let nameClassCategory = {};

            this.userConcernNoticeList.forEach(item => {
                oCategory[item.notice_id] = {id:item.notice_id,notice_id:item.notice_id,title:item.title,name:item.name,keyword:item.keyword,notice_url:item.notice_url,
                                           notice_reg:item.notice_reg,period_end:item.period_end};
                
                //nameClassCategory[item.name] = {name:item.name,type:item.type};

            });

            //let category1 = category1.sort((a,b)=> a.id > b.id)
            let display = []
            for (let key in oCategory){
              display.push(oCategory[key])
            }
            //console.log("category1",category1)
            //최신 날짜가 가장 앞에 오게 처리
            this.totalDisplayList =  display.sort((a,b)=> b.id - a.id);
            this.displayNoticeList = this.totalDisplayList;
            //this.displayNoticeList =  category1.sort().reverse();

            
            //console.log("displayNoticeList",this.displayNoticeList);

            this.userConcernNoticeClassCountList.push({name:'전체',notice_class_id:999,count:this.displayNoticeList.length})
            //console.log("this.userConcernNoticeClassCountList",this.userConcernNoticeClassCountList);
            this.userConcernNoticeClassCountList = this.userConcernNoticeClassCountList.sort((a,b)=> b.count - a.count);
            
             

             if(this.category != '전체'){
              //매번 가져오게 할건지 아니면 메모리 job으로 할건지..
              let categoryList = this.totalDisplayList.filter( c =>{
                  let temp = c.keyword.split(',');
                  //console.log("temp",temp)
                  //console.log("temp.indexOf(this.category)",temp.indexOf(this.category))
                  
                  return temp.indexOf(this.category) != -1;
              });
              
              //console.log("categoryList",categoryList);
              this.displayNoticeList = categoryList;
            }
            this.isLoading = false

          },
          changeCategory(){
            console.log("this.category",this.category);
            if(this.category == '전체'){
              //매번 가져오게 할건지 아니면 메모리 job으로 할건지..
              //this.getconcernNoticeList()
              this.displayNoticeList = this.totalDisplayList;
            }else{
              let categoryList = this.totalDisplayList.filter( c =>{
                  let temp = c.keyword.split(',');
                  //console.log("temp",temp)
                  //console.log("temp.indexOf(this.category)",temp.indexOf(this.category))
                  
                  return temp.indexOf(this.category) != -1;
              });
              
              //console.log("categoryList",categoryList);
              this.displayNoticeList = categoryList;
            }


          },
          getNoticeClickCount(notice_id){

            let NoticeClickCount = this.noticeTotalClickCountList.filter(c =>{
                    return c.notice_id == notice_id;
            });
            //console.log("NoticeClickCount",NoticeClickCount[0]);
            return NoticeClickCount[0].click_count;
          },
          getNoticeUrl(notice_id){

            let displayNotice = this.displayNoticeList.filter(c =>{
                    return c.id == notice_id;
            });
            //console.log("displayNotice",displayNotice[0]);
            return displayNotice[0].notice_url;
          },
          async goToDetail(notice_id){
            //console.log("notice_id",notice_id)
            //this.$router.push({path:'/detail', query:{notice_id:notice_id}});
            await this.$api("/api/plusUserConcernNoticeCount",{param:[this.tokens.user.id,notice_id,'detail_click',]});

            console.log("notice_url",this.getNoticeUrl(notice_id));
            window.open(this.getNoticeUrl(notice_id), "_blank"); 
            this.getconcernNoticeList();

            
          },
          async goToSite(notice_id){
            //console.log("notice_id",notice_id) //numbers.find(number => number > 2)
            //console.log("getNoticeUrl(notice_id)",this.getNoticeUrl(notice_id))
            //console.log("this.tokens.user.id",this.tokens.user.id);
            await this.$api("/api/plusUserConcernNoticeCount",{param:[this.tokens.user.id,notice_id,'link_click',]});
            console.log("notice_url",this.getNoticeUrl(notice_id));
            window.open(this.getNoticeUrl(notice_id), "_blank"); 
            this.getconcernNoticeList();

          },
          async deleteConcern(notice_id){
            this.$swal.fire({
              title: '목록삭제',
              html: '관심목록에서 삭제하시겠습니까?',
              showCancelButton: true,
              confirmButtonText: "삭제",
              cancelButtonText: "취소"
            }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                //console.log(product_id)
                await this.$api("/api/deleteUserConcernNotice",{param:[this.tokens.user.id,notice_id]});
                this.getconcernNoticeList();
                //this.$swal.fire("삭제되었습니다!", "", "success");
                  
              } 
            });

          }
        }
      }
    </script>