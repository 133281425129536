import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mixins from './mixins'
import store from './store'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import 'bootstrap' //npm install bootstrap --save
import 'bootstrap/dist/css/bootstrap.min.css'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
//import dayJs from 'dayjs'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/css/index.css'

//import InfiniteLoading from "v3-infinite-loading";
//import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots

const app = createApp(App);

app.use(router);
app.mixin(mixins);
app.use(store);
app.use(VueSweetalert2);
app.component('VueDatePicker', VueDatePicker);
app.component('vue-loading', VueLoading);
//app.component("infinite-loading", InfiniteLoading);
//app.use(InfiniteLoading);
app.mount('#app');

window.Kakao.init("83663e40f23da25952acca13e0faf44e")
