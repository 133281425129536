<template>
    <main class="mt-3">
      <div class="container">
        <div class="row mb-2">
          <vue-loading
            v-model:active="isLoading"
            :can-cancel="true"
            :is-full-page="fullPage"
          />
          <div class="col-4">
            <select class="form-select" v-model="gubun" @change="changeGubun" >
              <option :value="cate.name" :key=i v-for="(cate,i) in gubunList">{{cate.name}}</option>
            </select>
          </div>
          <div class="col-4 px-0">
            <VueDatePicker locale="ko"  :clearable="false" :enable-time-picker="false" :disabled-week-days="[6, 0]" v-model="date" hide-input-icon />
          </div>
          <div class="col-4">
            <select class="form-select" v-model="category" @change="changeCategory" >
              <option :value="cate.name" :key=i v-for="(cate,i) in noticeClassOptionCategoryList">{{cate.name+'('+cate.count+')'}}</option>
            </select>
          </div>
        </div>
        <div v-if="displayNoticeList.length > 0" class="row g-3">
          <template :key="i" v-for="(notice,i) in displayNoticeList" >
            <div class="col-xl-4 col-lg-6 col-md-12">
              <!--div class="card" style="width: 27rem;"-->
                <!--a @click="goToDetail(product.id);" style="cursor:pointer;">
                  <img :src="product.path" class="card-img-top" alt="...">
                </a-->
              <div class="card w-80" >
                <div :class="`card-body border shadow-sm ${getMyNoticeCount(notice.id) == 0 ? 'border-light':'border-info border-3'}`">
                  <span v-if="notice.click_count ==0"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">{{notice.click_count}}</span>
                  <span v-else-if="notice.click_count > 0 && notice.click_count <=3"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info">{{notice.click_count}}</span>
                  <span v-else-if="notice.click_count > 3 && notice.click_count <10"  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning">{{notice.click_count}}</span>
                  <span v-else  class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{notice.click_count}}</span>
                  <a @click="goToDetail(notice.id);" style="cursor:pointer;">
                    <h5 class="card-text text-start text-wrap lh-base fw-semibold"  style="height: 6rem;">{{notice.title}}</h5>
                  </a>
                  <p v-if="noticeClassList[notice.id] == ''" class="card-text text-start text-secondary" style="height: 2rem;">{{ "기타" }}</p>
                  <p v-else class="card-text text-start text-secondary " style="height: 2rem;">{{ noticeClassList[notice.id].join(",") }}</p>
                  <!--p class="card-text text-start" >
                      <template :key="i" v-for="(class_name,i) in noticeClassList[notice.id]" >
                          <span v-if="class_name.name == null" class="badge border border-secondary text-secondary py-1 me-1">{{ "기타" }}</span> 
                          <span v-else-if = "class_name.type == '1' || class_name.type == '13'" class="badge border border-primary text-primary py-1 me-1">{{class_name.name}}</span>
                          <span v-else-if = "class_name.type == '2' || class_name.type == '9'" class="badge border border-secondary text-secondary py-1 me-1">{{class_name.name}}</span>
                          <span v-else-if = "class_name.type == '3' || class_name.type == '10'" class="badge border border-success text-success py-1 me-1">{{class_name.name}}</span>
                          <span v-else-if = "class_name.type == '4' || class_name.type == '14'" class="badge border border-danger text-danger py-1 me-1">{{class_name.name}}</span>
                          <span v-else-if = "class_name.type == '5' || class_name.type == '11'" class="badge border border-warning text-warning py-1 me-1">{{class_name.name}}</span>
                          <span v-else-if = "class_name.type == '6' || class_name.type == '8'" class="badge border border-info text-info py-1 me-1">{{class_name.name}}</span>
                          <span v-else class="badge border border-dark text-dark py-1 me-1">{{class_name.name}}</span>

                      </template>
                    <span :key="i" v-for="(class_name,i) in noticeClassList[notice.id]" 
                    class="badge border border-danger text-danger py-1 me-1">{{class_name.name}}</span>
                
                  </p-->
                  <div class="d-flex justify-content-between align-items-center" >
                    <div class="btn-group" role="group" aria-label="Basic example">
                      <button type="button" class="btn btn-md btn-primary btn-block text-white fw-semibold"  @click="goToSite(notice.id);">공고 바로가기</button>
                    </div>
                    <small v-if="notice.notice_reg==notice.period_end" class="text-dark"></small>
                    <small v-else class="text-dark">접수마감 : {{notice.period_end}}</small>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="row g-3">
          <div class="col-12">
            <div class="card w-80" >
              <div class="card-body border shadow-sm 'border-info">
                <h6 class="card-text text-center text-wrap lh-base fw-semibold"  style="height: 6rem;">{{dateString}}일 현재까지 수집된 정보가 없습니다. <br>1~2시간에 한번씩 데이터를 수집해서 제공합니다.
                <br>다른 날짜로 변경하시거나 <br> 잠시 후 다시 접속해 주시기 바랍니다.</h6>
              </div>

            </div>
          </div>
        </div>
        
        <div v-if="this.tokens.user == undefined && todayNoticeCount > 0" class="row g-3">
          <div class="text-center pt-4 mb-4 pb-1">
                        <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold shadow-sm" type="button" @click="userLogin">더보기({{todayNoticeCount-3}}건)</button>
                      </div>
        </div>
      </div>
    </main>
    </template>
    <script>
      import dayJs from 'dayjs'
      export default {
        data(){
          return {
            todayNoticeList: [],
            todayMyNoticeCountList:[],
            todayMyNoticeCountCategoryList: [],
            totalDisplayList:[],
            displayNoticeList : [],
            noticeClassList : [],
            noticeClassOptionList: [],
            noticeClassOptionCategoryList: [],
            gubunList:[],
            category:"전체",
            gubun:'위원모집',
            date:new Date(),
            todayNoticeCount: 0,
            isLoading: false,
            fullPage: true
           
          };
        },
        created(){


        },
        watch:{
          'date': function() {
            this.changeDate();
          },
        },
        computed: {

          tokens(){{
            return this.$store.state.tokens;
          }},

           dateString(){{
            return this.getDateFormat()
           }}
        },
        mounted(){
          this.verifyTokenStatus();
          this.getGubunList();

          this.getTodayNoticeList();
        },
        methods:{
          userLogin(){
            this.$swal.fire({
                title: '로그인',
                html: '로그인/회원가입 후 이용하실 수 있습니다.',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(async (result) => {
                console.log(result);
                this.$router.push({path:'/login'});

              });
          },
          getDateFormat(){
            return dayJs(this.date).format("MM/DD/YYYY")
          },
          isWeekEnd(){
            let weekend = dayJs(this.date).day();
            if (weekend == 0 || weekend == 6){
              return true;
            }else{
              return false;
            }
          },
          async verifyTokenStatus(){
            console.log("this.tokens",this.tokens)
            if(this.tokens.user == undefined) {
              // this.$swal.fire({
              //   title:'로그인',
              //   text: '로그인을 하셔야 서비스를 이용하실 수 있습니다.',
              //   icon: 'error',
              //   confirmButtonText: '확인'
              // });
              // this.$router.push({path:'/'});
              console.log("this.tokens.user == undefined")
            }else if (this.tokens.user.login_type == 1){
              const headers_config = {
                "login_id": this.tokens.user.login_id,
                "refresh": this.tokens.refreshToken,
                Authorization: `Bearer ${this.tokens.accessToken}`   
              };
              const res = await this.$verifyToken(headers_config);
              console.log("verifyToken",res)
              
              if(res.data.code == 500){
                this.$swal.fire({
                  title:'시스템 오류',
                  text: '시스템에 접속할 수 없습니다. 관리자에게 문의해 주시기 바랍니다..',
                  icon: 'error',
                  confirmButtonText: '확인'
                });
              }else if(res.data.code == 401){
                console.log("res.code",res.code)
                this.$swal.fire({
                  title:'세션만료',
                  text: '로그인을 다시 하시기 바랍니다.',
                  icon: 'error',
                  confirmButtonText: '확인'
                });
                this.$router.push({path:'/login'});
              }
            }
          },
          getGubunList(){
              if( this.tokens.user !=undefined && this.tokens.user.alert_type == 7){
                this.gubunList[0]={name:'위원모집',code:'1'}
                this.gubunList[1]={name:'입찰공고',code:'2'}
              }else{
                this.gubunList[0]={name:'위원모집',code:'1'}
              }

          },
          changeGubun(){
            console.log("this.gubun",this.gubun);
            if(this.gubun == '위원모집'){
              //매번 가져오게 할건지 아니면 메모리 job으로 할건지..
              this.getTodayNoticeList()
              //this.displayNoticeList = this.totalDisplayList;
            }else{
              console.log("this.gubun",this.gubun);
              //this.getTodayBidList()
            }


          },
          changeDate(){
            if( this.tokens.user !=undefined){
              this.getTodayNoticeList();
            }else{
              this.$swal.fire({
                  title: '로그인',
                  html: '로그인 / 회원가입 후 이용하실 수 있습니다.',
                  icon: 'success',
                  confirmButtonText: '확인'
                }).then(async (result) => {
                  console.log(result);
                  this.$router.push({path:'/login'});

                });
            }
          },
          getMyNoticeCount(notice_id){
            if (this.todayMyNoticeCountCategoryList[notice_id] == undefined){
              return 0;
            }else{
              return this.todayMyNoticeCountCategoryList[notice_id].click_count;
            }
          },
          async getTodayNoticeList(){
            this.isLoading = true
            let todayNList = {};
            //console.log("this.getDateFormat()",this.getDateFormat());
            //console.log("this.$store",this.$store);
            //console.log("this.tokens",this.tokens);

            if(this.tokens.user == undefined) {
              let res = await this.$api("/api/todayNoticeNotLogin",{param:[this.getDateFormat()]});
              //console.log("res",res);
              this.todayNoticeCount = res.data.todayNoticeCount;
              todayNList = res.data.todayNoticeList;
              //let countResult = await this.$api("/api/todayNoticeCount",{param:[this.getDateFormat()]});
              //console.log("countResult",countResult);
              //this.todayNoticeCount = countResult[0].count
              //console.log("this.todayNoticeCount",this.todayNoticeCount);
              //todayNoticeList = await this.$api("/api/todayNoticeListNotLogin",{param:[this.getDateFormat()]});
            }else{
              //console.log("this.tokens.user.alert_type",this.tokens.user.alert_type);
              //console.log("this.tokens.user.user_id",this.tokens.user.user_id);
              let res ={}
              if (this.tokens.user.alert_type == 1 || this.tokens.user.alert_type == 5 || this.tokens.user.alert_type == 100){
                res = await this.$api("/api/todayNoticeLogin",{param:[this.getDateFormat(),this.tokens.user.id,'all']});
              }else{
                res = await this.$api("/api/todayNoticeLogin",{param:[this.getDateFormat(),this.tokens.user.id,'my']});
              }
              //console.log("res",res);
              this.todayMyNoticeCountList = res.data.todayMyNoticeCountList;
              todayNList = res.data.todayNoticeList;

            }

            this.todayNoticeList = todayNList;
            //console.log("this.todayNoticeList",this.todayNoticeList);
            //console.log("this.todayMyNoticeCountList",this.todayMyNoticeCountList);
            if(this.todayMyNoticeCountList.length > 0){
              this.todayMyNoticeCountList.forEach(item => {
                this.todayMyNoticeCountCategoryList[item.notice_id] = {click_count:item.click_count,click_count_link:item.click_count_link};

              });
              //console.log("this.todayMyNoticeCountCategoryList",this.todayMyNoticeCountCategoryList);
            }
            let oCategory = {};
            let nameClassCategory = {};

            this.todayNoticeList.forEach(item => {
                oCategory[item.id] = {id:item.id,title:item.title,name:item.name,type:item.type,click_count:item.click_count,notice_url:item.notice_url,
                                        my_click_count:item.my_click_count,notice_reg:item.notice_reg,period_end:item.period_end};
                
                nameClassCategory[item.name] = {name:item.name,type:item.type};

            });
            // let categoryList = this.todayNoticeList.filter( c =>{
            //       return c.name == this.category;
            //   });
            //let category1 = category1.sort((a,b)=> a.id > b.id)
            let display = []
            for (let key in oCategory){
              display.push(oCategory[key])
            }
            //console.log("display",display)
            //최신 날짜가 가장 앞에 오게 처리
            this.totalDisplayList =  display.sort((a,b)=> b.id - a.id);
            this.displayNoticeList = this.totalDisplayList;
            //this.displayNoticeList =  category1.sort().reverse();

            
            //console.log("displayNoticeList",this.displayNoticeList)

            for (let key in oCategory){
                
                let category2 = this.todayNoticeList.filter(c =>{
                    return c.id == key;
                });
                //console.log("category2",category2);
                let notice_class =[]
                category2.forEach((item,index) =>{
                  notice_class[index] = [item.name]
                });
                this.noticeClassList[key] = notice_class;
                //this.noticeClassList[key] = category2;
            }
            //console.log("noticeClassList",this.noticeClassList);
            //console.log("nameClassCategory",nameClassCategory);
            let noticeClassOption = [];
            for (let key in nameClassCategory){
                
                let nameClass = this.todayNoticeList.filter(c =>{
                    return c.name == key;
                });
                //console.log("category3",category3);

                let notice ={}
                
                nameClass.forEach(item =>{
                  notice[item.id] = {id:item.id,title:item.title,name:item.name,type:item.type,click_count:item.click_count,notice_url:item.notice_url,
                    my_click_count:item.my_click_count,notice_reg:item.notice_reg,period_end:item.period_end};
                });
                noticeClassOption[key] = notice;

                
                //this.noticeClassList[key] = category2;
            }
            this.noticeClassOptionList = noticeClassOption;
            //console.log("this.noticeClassOptionList",this.noticeClassOptionList);
            let noticeClassOptionCategory = []
            noticeClassOptionCategory.push({name:'전체',count:this.displayNoticeList.length})
            for (let key in noticeClassOption){
              noticeClassOptionCategory.push({name:key,count:Object.values(noticeClassOption[key]).length});
            }
            this.noticeClassOptionCategoryList = noticeClassOptionCategory.sort((a,b)=> b.count - a.count);
            //console.log("this.noticeClassOptionCategoryList",this.noticeClassOptionCategoryList);
            
            //console.log("this.category",this.category);
            if(this.category == '전체'){
              //매번 가져오게 할건지 아니면 메모리 job으로 할건지..
              //this.getTodayNoticeList()
              //this.displayNoticeList = this.totalDisplayList;
            }else{
              let categoryList = this.todayNoticeList.filter( c =>{
                  return c.name == this.category;
              });
              //console.log("categoryList",categoryList);
              this.displayNoticeList = categoryList;
            }
            this.isLoading = false


          },
          changeCategory(){
            console.log("this.category",this.category);
            if(this.category == '전체'){
              //매번 가져오게 할건지 아니면 메모리 job으로 할건지..
              //this.getTodayNoticeList()
              this.displayNoticeList = this.totalDisplayList;
            }else{
              let categoryList = this.todayNoticeList.filter( c =>{
                  return c.name == this.category;
              });
              //console.log("categoryList",categoryList);
              this.displayNoticeList = categoryList;
            }


          },

          getNoticeUrl(notice_id){

            let displayNotice = this.displayNoticeList.filter(c =>{
                    return c.id == notice_id;
            });
            //console.log("displayNotice",displayNotice[0]);
            return displayNotice[0].notice_url;
          },

          async goToDetail(notice_id){
            console.log("notice_id",notice_id)
            //this.$router.push({path:'/detail', query:{notice_id:notice_id}});
            if(this.tokens.user == undefined) {
              await this.$api("/api/updateNoticeClickCount",{param:[notice_id]});
              //await this.$api("/api/updateUserNoticeAlertClickCount",{param:[notice_id,this.user.id]});
            }else{
              await this.$api("/api/plusNoticeCount",{param:[this.tokens.user.id,notice_id,'detail_click',this.noticeClassList[notice_id].join(",")]});
            }
            //console.log("notice_url",this.getNoticeUrl(notice_id));
            window.open(this.getNoticeUrl(notice_id), "_blank"); 
            this.getTodayNoticeList();
          },
          async goToSite(notice_id){
            console.log("notice_id",notice_id) //numbers.find(number => number > 2)
            //console.log("this.getNoticeUrl(notice_id)",this.getNoticeUrl(notice_id))
            if(this.tokens.user == undefined) {
              await this.$api("/api/updateNoticeClickCount",{param:[notice_id]});
              //await this.$api("/api/updateUserNoticeAlertClickCount",{param:[notice_id,this.user.id]});
            }else{
              //console.log("this.tokens.user.id",this.tokens.user.id);
              await this.$api("/api/plusNoticeCount",{param:[this.tokens.user.id,notice_id,'link_click',this.noticeClassList[notice_id].join(",")]});
            }
            //console.log("notice_url",this.getNoticeUrl(notice_id));
            window.open(this.getNoticeUrl(notice_id), "_blank"); 
            this.getTodayNoticeList();
          }
        }
      }
    </script>