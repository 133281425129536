<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-dark sticky-top border-bottom shadow" data-bs-theme="dark">
      <div class="container">
        <a class="navbar-brand d-md-none" href="/">
          <!--svg class="bi" width="24" height="24"><use xlink:href="#aperture"/></svg-->
          Noti-X
        </a>
        <button id="offcanvasToggler" class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end w-50" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasLabel">Noti-X</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav flex-grow-1 justify-content-between">
              <!-- <li class="nav-item"><router-link class ="nav-link" @click.prevent="closeOffcanvas()" active-class="active" to="/">Noti-X</router-link></li>
              <li class="nav-item"><router-link class ="nav-link" @click.prevent="closeOffcanvas()" active-class="active" to="/today">오늘 공고</router-link></li>
              <li class="nav-item"><router-link class ="nav-link" @click.prevent="closeOffcanvas()" active-class="active" to="/concern">관심 목록</router-link></li>
              <li class="nav-item"><a class="nav-link" href="#" @click.prevent="closeOffcanvas()" data-bs-toggle="modal" data-bs-target="#applyPrepareConfirm">신청서 관리</a></li>
              <li class="nav-item"><a class="nav-link" href="#" @click.prevent="closeOffcanvas()" data-bs-toggle="modal" data-bs-target="#statsPrepareConfirm">통계/분석</a></li>
              <li class="nav-item"><a class="nav-link" href="#" @click.prevent="closeOffcanvas()" data-bs-toggle="modal" data-bs-target="#recommendPrepareConfirm">지인 추천</a></li>
              <li class="nav-item"><a class="nav-link" href="#" @click.prevent="closeOffcanvas()" data-bs-toggle="modal" data-bs-target="#payPrepareConfirm">내정보/결제</a></li>
              <li v-if="this.tokens.user == undefined" class="nav-item"><router-link class ="nav-link" @click.prevent="closeOffcanvas()" active-class="active" to="/login">로그인/회원가입</router-link></li>
              <li v-else class="nav-item"><a class="nav-link lh-base" href="#"  @click.prevent="closeOffcanvas()" @click="notixLogout">로그아웃</a></li> -->

              <li class="nav-item"><a class="nav-link active" href="/">
                <svg class="bi" width="24" height="24"><use xlink:href="#aperture"/></svg>
                Noti-X
              </a></li>
              <li class="nav-item"><a class="nav-link" href="/today">오늘 공고</a></li>
              <li class="nav-item"><a class="nav-link" href="/concern">관심 목록</a></li>
              <li class="nav-item"><a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#applyPrepareConfirm">신청서 관리</a></li>
              <li class="nav-item"><a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#statsPrepareConfirm">통계/분석</a></li>
              <li class="nav-item"><a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#recommendPrepareConfirm">지인 추천</a></li>
              <li class="nav-item"><a class="nav-link" href="#" data-bs-toggle="modal" data-bs-target="#payPrepareConfirm">내정보/결제</a></li>
              <li v-if="this.tokens.user == undefined" class="nav-item"><a class="nav-link" href="/login" >로그인/회원가입</a></li>
              <li v-else class="nav-item"><a class="nav-link lh-base" href="#"  @click="notixLogout">로그아웃</a></li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
      <router-view/>
      <!--footer class="d-flex flex-wrap justify-content-between align-items-center mt-5 py-3 my-4 border-top bg-dark">
      <p class="col-md-4 mb-0 text-white text-center">© 2024 Sinnanuen corp All Rights Reserved</p>

      </footer-->
      <footer class="mt-5 py-5 bg-dark overflow-hidden">
        <div class="row">
          <div class="col-12 col-md">
            <small class="d-block mb-3 text-white">&copy; 2024 Sinnaneun Corp. All Rights Reserved</small>
            <small class="d-block mb-3 text-white">신나는 주식회사(사업자번호 683-81-00750)가 본 서비스를 제공하고 있으며<br> 게시된 정보가 무단으로 수집되는 것을 거부합니다.</small>
            <small class="d-block mb-3 text-white">email : kim@sinnaneun.co.kr</small>
          </div>
          
          <!--div class="col-6 col-md">
            <h5>Resources</h5>
            <ul class="list-unstyled text-small">
              <li><a class="link-secondary text-decoration-none" href="#">Resource name</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Resource</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Another resource</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Final resource</a></li>
            </ul>
          </div>
          <div class="col-6 col-md">
            <h5>Resources</h5>
            <ul class="list-unstyled text-small">
              <li><a class="link-secondary text-decoration-none" href="#">Business</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Education</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Government</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Gaming</a></li>
            </ul>
          </div>
          <div class="col-6 col-md">
            <h5>About</h5>
            <ul class="list-unstyled text-small">
              <li><a class="link-secondary text-decoration-none" href="#">Team</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Locations</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Privacy</a></li>
              <li><a class="link-secondary text-decoration-none" href="#">Terms</a></li>
            </ul>
          </div-->
        </div>
      </footer>
      <!-- Modal -->
      <div class="modal fade" id="applyPrepareConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">서비스 준비중</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>서비스 준비중입니다.</p>
              <p>기존 작성하셨던 신청서 PDF 파일을 업로드하시면 </p>
              <p>AI를 활용, 신청서를 자동 작성하는 기능을 제공할 예정입니다. </p>
              <p>빠른 시간내에 서비스를 제공할 수 있도록 노력하겠습니다.</p>
              <p>감사합니다.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div> 
      </div>
      <!-- Modal -->
      <div class="modal fade" id="statsPrepareConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">서비스 준비중</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>서비스 준비중입니다.</p>
              <p>입찰공고,낙찰 정보, 평가위원 공고, 결과등에 대한 정보를</p>
              <p>분석하고 다양한 통계 정보를 제공할 예정입니다. </p>
              <p>빠른 시간내에 서비스를 제공할 수 있도록 노력하겠습니다.</p>
              <p>감사합니다.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div> 
      </div>  
      <!-- Modal -->
      <div class="modal fade" id="recommendPrepareConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">서비스 준비중</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>서비스 준비중입니다.</p>
              <p>저희 Noti-X 서비스를 지인에게 추천해 주시면 </p>
              <p>유료화 전환시 1개월 무료 이용권을 제공할 예정입니다. </p>
              <p>빠른 시간내에 서비스를 제공할 수 있도록 노력하겠습니다.</p>
              <p>감사합니다.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div> 
      </div>  
      <!-- Modal -->
      <div class="modal fade" id="payPrepareConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">서비스 준비중</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <p>서비스 준비중입니다.</p>
              <p>내 정보 수정, 이용료 결제, 무료 이용권 등의 </p>
              <p>서비스를 제공할 예정입니다. </p>
              <p>빠른 시간내에 서비스를 제공할 수 있도록 노력하겠습니다.</p>
              <p>감사합니다.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div> 
      </div>    
  </div>
</template>

<script>
 import * as bootstrap from "bootstrap"
  export default {
    computed:{
      user(){
        return this.$store.state.user;
      },
      tokens(){{
            return this.$store.state.tokens;
          }},
    },
    methods:{
      closeOffcanvas(){ 
        let myOffcanvas = document.getElementById('offcanvas'); 
        let bsOffcanvas = bootstrap.Offcanvas.getInstance(myOffcanvas); 
        bsOffcanvas.hide();  
      },
      // closeOffcanvas() {
      // let toggleButton = document.getElementById('offcanvasToggler');
      // alert("toggleButton.style.display[",toggleButton.selected,"]")
      // if (toggleButton != 'hidden')
      //   toggleButton.click()

      // },
      kakaoLogout(){
        window.Kakao.Auth.logout((response) =>{
          console.log(response);
          this.$store.commit("user",{});
          alert("로그아웃");
        });
      },
      emailLogout(){
        console.log("emailLogout")
        this.$store.commit("tokens",{});
      },
      notixLogout(){
        window.Kakao.Auth.logout()
        console.log(window.Kakao.Auth.getAccessToken()); // null
        console.log("notixLogout")
        this.$store.commit("tokens",{});
        // if(this.tokens.user.login_type==1){
        //   this.$store.commit("tokens",{});
        // }else if(this.tokens.user.login_type==2){
          
        //   this.$store.commit("tokens",{});
        // }
        this.$swal.fire({
          title: '로그아웃',
          html: '로그아웃 하였습니다.',
          icon: 'success',
          confirmButtonText: '확인'
        }).then(async (result) => {
            console.log(result);
            this.$router.go({path:'/'});

        });
        

      

      }
      
    }
  }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
