<template>
<div class="container py-4">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-xl-4 col-lg-6">
            <div class="card rounded-3 text-black shadow-sm">
              <div class="row g-0">
                <div class="col-lg-12">
                  <div class="card-body p-md-1 mx-md-4 " >
                    <div class="text-center mt-3 mb-1 pb-1">
                      <img src="@/assets/images/notix_log_login.png">
                      <h2 class="mt-2 mb-1 pb-1 fw-semibold">서비스 이용 약관</h2>
                    </div>
                    <div class="modal-body">
              <pre style="text-align:left; white-space: pre-wrap;  word-break: break-all;  overflow: auto;">
총칙

제1조(목적)

이 약관은 '신나는 주식회사' (이하 “회사”라 합니다)가 전기통신 사업법령 및 정보통신망 이용 촉진 등에 관한 법령에 의하여 회사가 제공하는 모든 서비스 의 이용조건 및 절차에 관한 사항을 규정함을 목적으로 합니다.

 

제2조(약관의 공지 및 준용)

① 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 효력을 발생합니다.

② 회사는 이 약관을 임의로 변경할 수 있으며, 변경된 약관은 제1항과 같은 방법으로 공지함으로써 효력을 발생합니다.

 

제3조(약관 외 준칙)

이 약관에 명시되지 않은 사항은 전기통신 기본법 , 전기통신 사업법, 정보통신망 이용촉진등에 관한 법률 및 기타 관련법령의 규정에 의합니다.

 

제4조(용어의 정의)

이 약관에서 사용하는 용어의 정의는 다음과 같습니다.

① 회원 : 회사와 서비스 이용계약을 체결하고 이용자 아이디를 부여받은 자.

② 아이디(ID) : 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 문자와 숫자의 조합

③ 비밀번호 : 회원의 비밀 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합

④ 운영자 : 서비스의 전반적인 관리와 원활한 운영을 위하여 회사에서 선정한 사람

⑤ 해지 : 회사 또는 회원이 서비스 개통 후 이용계약을 해약하는 것

 

 

서비스 이용계약

제5조(이용계약의 성립)

① 회원으로 가입하는 것은 이 약관에 동의하는 것으로 간주됩니다.

② 이용계약은 회원의 이용신청에 대하여 회사가 심사 후 승낙함으로써 성립합니다.

 

제6조(회원정보 사용에 대한 동의)

① 회원의 개인 정보에 대해서는 회사의 개인정보 보호정책이 적용됩니다.

② 회사가 신청서에 기재를 요구하는 회원의 개인정보는 본 이용계약의 이행과 본 이용계약상의 서비스제공을 위한 목적으로 이용합니다.

③ 회원이 회사 및 회사와 제휴한 서비스들을 편리하게 이용할 수 있도록 하기 위해 공식 제휴약정을 맺은 제휴업체에 회원정보를 제공 및 공유할 수 있습니다.

④ 회원은 회원정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수 있습니다.

⑤ 회원이 이용신청서에 회원정보를 기재하고, 회사에 본 약관에 따라 이용신청을 하는 것은 회사가 본 약관에 따라 이용신청서에 기재된 회원정보를 수집, 이용 및 제공하는 것에 동의하는 것으로 간주됩니다.

⑥ 회사에 본 약관에 따라 이용신청을 하는 것은 회원의 사용문의, 오류 상담시 회사에서 대리 로그인을 통해 해결할 수 있도록 동의한 것으로 간주합니다.

 

제7조(이용신청의 승낙)

① 회사는 회원이 모든 사항을 정확히 기재하여 이용신청을 하였을 때 제2호, 제3호의 경우를 예외로 하여 승낙할 수 있습니다.

② 회사는 다음 각 호에 해당하는 이용신청에 대하여는 승낙을 유보할 수 있습니다.

㉮ 설비에 여유가 없는 경우

㉯ 기술상 지장이 있는 경우

㉰ 기타 회사의 사정상 이용승낙이 곤란한 경우

③ 회사는 다음의 경우에 해당하는 이용신청에 대하여는 이를 승낙하지 아니 할 수 있습니다.

㉮ 가입자명(상호 , 이름)이 실명이 아닌 경우

㉯ 다른 사람의 명의를 사용하여 신청한 경우

㉰ 이용신청시 필요내용을 허위로 기재하여 신청한 경우

㉱ 사회의 안녕 , 질서 또는 미풍양속을 저해할 목적으로 신청한 경우

㉲ 기타 회사가 정한 이용신청요건이 미비 되었을 때

 

제8조(계약사항의 변경)

회원은 이용 신청시 기재한 사항이 변경되었을 경우에는 온라인으로 수정을 해야 하고 미변경으로 인하여 발생되는 문제의 책임은 회원에게 있습니다.

 

 

계약당사자의 의무

제9조(회사의 의무)

① 회사는 서비스 제공과 관련해서 알고 있는 회원의 신상정보를 제 6조 3항의 절차 없이 제 3자에게 누설, 배포하지 않습니다. 단, 전기통신 기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이 있는 경우 또는 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그러하지 않습니다.

② 1항의 범위 내에서, 회사는 업무와 관련하여 회원 전체 또는 일부의 개인정보에 관한 통계 자료를 작성하여 이를 사용할 수 있고, 서비스를 통하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터의 브라우져의 설정을 변경할 수 있습니다.

 

제10조(회원의 의무)

① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.

㉮ 다른 회원의 ID를 부정하게 사용하는 행위 및 본인 ID를 제 3자에게 제공하는 행위

㉯ 서비스에서 얻은 정보를 회사의 사전승낙 없이 회원의 이용이외의 목적으로 복제하거나 이를 웹사이트에 게시,출판 및 방송 등에 사용하거나 제3자에게 제공하는 행위

㉰ 회사의 저작권, 제3자의 저작권등 기타 권리를 침해하는 행위

㉱ 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을 타인에게 유포하는 행위

㉲ 범죄와 결부된다고 객관적으로 판단되는 행위

㉳ 기타 관계법령에 위배되는 행위

② 회원은 이 약관에서 규정하는 사항과 서비스 이용안내 또는 주의사항을 준수하여야 합니다.

③ 회원은 내용별로 회사가 서비스 공지사항에 게시하거나 별도로 공지한 이용제한 사항을 준수하여야 합니다.

④ 회원은 회사의 사전 승낙 없이는 서비스를 이용하여 영업활동을 할 수 없으며, 영업활동의 결과와 회원이 약관에 위반한 영업활동을 이용하여 발생한 결과에 대하여 회사는 책임을 지지 않습니다. 회원은 이와 같은 영업활동에 대하여 회사에 대하여 손해배상 의무를 집니다.

⑤ 회원은 회사의 명시적인 동의가 없는 한 서비스의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없으며, 이를 담보로 제공할 수 없습니다.

⑥ 회원은 회사에서 제공된 정보에 대하여 입찰에 관련된 행위를 하기 전에 해당 발주처에 입찰일자, 자격 및 금액등 입찰관련 내용을 직접 확인하여야 한다.

 

 

서비스이용

제11조(회원 아이디[ID]와 비밀번호 관리에 대한 회원의 의무)

① 1아이디는 1인 사용을 원칙으로 합니다.

② 아이디(ID)와 비밀번호에 관한 모든 관리책임은 회원에게 있습니다. 회원에게 부여된 아이디(ID)와 비밀번호의 관리소홀 , 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 회원에게 있습니다.

③ 자신의 아이디(ID)가 부정하게 사용된 경우 회원은 반드시 회사에 그 사실을 통보해야 합니다.

 

제12조(정보의 제공)

회사는 회원이 서비스 이용 중 필요가 있다고 인정되는 다양한 정보에 대해서 카카오 알림톡, SMS, LMS 메세지, 전자우편, 서신우편 등의 방법으로 회원에게 제공할 수 있으며 회원은 원치 않을 경우 정보수신거부를 할 수 있습니다.

 

제13조(게시물 관리)

회사는 회원이 게시하거나 등록하는 서비스내의 내용물이 다음 각 호의 1에 해당한다고 판단되는 경우에 사전통지없이 삭제할 수 있습니다.

① 당사 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우

② 공공질서 및 미풍양속에 위반되는 내용인 경우

③ 범죄적 행위에 결부된다고 인정되는 내용일 경우

④ 회사의 저작권, 제 3 자의 저작권 등 기타 권리를 침해하는 내용인 경우

⑤ 회사에서 규정한 게시기간을 초과한 경우

⑥ 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트를 링크하는 경우

⑦ 기타 관계법령에 위반된다고 판단되는 경우

 

제14조(게시물의 저작권 귀속 및 이용제한)

서비스에 게재된 자료에 대한 권리는 다음 각 호와 같습니다.

① 게시물에 대한 권리와 책임은 게시자에게 있으며 회사는 게시자의 동의 없이는 이를 서비스내 게재 이외에 영리적 목적으로 사용하지 않습니다. 단, 비영리적인 경우에는 그러하지 아니하며 또한 회사는 서비스내의 게재권을 갖습니다.

② 회원은 서비스를 이용하여 얻은 정보를 회사의 사전 승낙없이 회원의 서비스 이용이외의 목적으로 복제하거나 이를 변경, 송신, 출판, 배포 및 방송 기타 방법 등에 의하여 영리 목적으로 이용할 수 없으며, 회원사의 영리목적을 위한 제품 실현의 수단이 될 수 없습니다.

 

제15조(서비스 이용시간)

① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 정한 날이나 시간은 그러하지 않습니다.

② 회사는 서비스를 일정범위로 분할하여 각 범위별로 이용가능 시간을 별도로 정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.

 

제16조(서비스 이용책임)

회원은 회사에서 권한 있는 사원이 서명한 명시적인 서면에 구체적으로 허용한 경우를 제외하고는 서비스를 이용하여 불법상품을 판매하는 영업활동을 할 수 없으며 특히 해킹 , 돈벌기 광고 , 음란사이트를 통한 상업행위, 상용S/W 불법배포 등을 할 수 없습니다. 이를 어기고 발생한 영업활동의 결과 및 손실, 관계기관에 의한 구속 등 법적 조치등에 관해서는 회사가 책임을 지지 않습니다.

 

제17조(서비스 제공의 중지)

① 회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다.

㉮ 서비스용 설비의 보수 등 공사로 인한 부득이한 경우

㉯ 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우

㉰ 기타 불가항력적 사유가 있는 경우

② 회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를 제한하거나 정지할 있습니다.

 

제18조(세금계산서 발행)

① 본 서비스의 이용요금에는 부가가치세가 포함된 금액으로써 회원이 입금한 이용료에 대하여 본 사이트 메인페이지에 회원이 쉽게 볼 수 있는 배너를 통해 세금계산서를 발행합니다.

② 웹사이트 내에 세금계산서를 발행함으로 세금계산서 발급에 대한 의무를 다 한 것으로 하며, e-mail 또는 우편으로 별도의 세금계산서를 발행하지 않습니다.

③ 회원은 웹사이트 내에 발행된 세금계산서를 언제나 확인, 인쇄하여 사용할 수 있습니다.

④ "신나는 주식회사"의 웹을 통해 발행된 세금계산서 서비스는 부가가치세법 제16조에 준하여 제공되며, 실제 세금계산서와 동일한 효력이 있습니다.

⑤ "신나는 주식회사"의 전자세금계산서는 동법령 상의 규정에 의거하여 그 발급 내용이 서버에 자동 저장되므로 위조, 변조, 기타 편집의 모든 책임은 이용자의 그 해당 업체에 있습니다.

 

 

계약해지 및 이용제한

제19조(계약해지 및 이용제한)

① 회원이 이용계약을 해지하고자 하는 때에는 회원 본인이 온라인을 통해 회사에 해지 신청을 하여야 합니다.

② 회사는 회원이 다음 각 호의 1에 해당하는 행위를 하였을 경우 사전통지 없이 이용계약을 해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.

가. 타인의 서비스 ID 및 비밀번호를 도용한 경우

나. 서비스의 정상적인 운영을 방해하거나 이를 시도한 경우

다. 가입한 이름이 실명이 아닌 경우

라. 같은 사용자가 다른 ID로 이중등록을 한 경우

마. 공공질서 및 미풍양속에 저해되는 내용을 고의로 유포시킨 경우

바. 회원이 국익 또는 사회적 공익을 저해할 목적으로 서비스이용을 계획 또는 실행하는 경우

사. 타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우

아. 다량의 정보전송 또는 광고성 정보전송 등의 형태로 서비스의 정상적인 운영에 영향을 주거나 이와 관련된 행위를 시도한 경우

자. 정보통신설비의 오작동이나 정보 등의 파괴를 유발시키는 컴퓨터 바이러스 프로그램 등을 유포하는 경우

차. 회사, 다른 회원 또는 제3자의 지적재산권을 침해하는 경우

카. 정보통신윤리위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여 선거관리위원회의 유권해석을 받은 경우

타. 타인의 개인정보, 이용자ID 및 비빌번호를 부정하게 사용하는 경우

파. 회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙없이 복제 또는 유통시키거나 상업적으로 이용하는 경우

하. 회원이 자신의 홈페이지와 게시판에 음란물을 게재하거나 음란사이트 링크하는 경우

거. 본 약관을 포함하여 기타 회사가 정한 이용조건에 위반한 경우

③ 회사가 제공하는 서비스 운영정책을 준수하지 않거나 비합리적인 요청 등으로 회사의 업무를 방해하는 행위를 한 경우 기타 "회사"가 합리적인 판단에 기하여 "서비스"의 제공을 거부할 필요가 있다고 인정할 경우 회사는 서비스 이용을 제한하거나 계약을 해지시킬 수 있습니다.

④ 본 약관에 따라 계약이 해지된 경우 회원자격이 상실되며, 기 납부한 정보이용료는 환불되지 않습니다. 또한 회원 자격을 상실할 경우 회원으로서의 모든 혜택은 전부 소멸될 뿐 아니라 관련법에 의한 책임을 묻게 됩니다.

 

손해배상 및 이용료 환불 등

제20조(손해배상)

회사의 서비스 범위는 각지에 흩어져있는 정보를 이용하기 편리하게 한 곳에 모아 제공하는데 한 하며 본 서비스 이용과 관련하여 게시된 정보의 오류 및 누락 등에 관한 어떠한 손해에 관하여도 책임을 지지 않습니다.

※ 위 사항 외의 별도 해지 수수료는 없습니다.

 

제21조(이용료 환불)

① 이용료의 환불은 아래와 같이 계산되어 시행합니다.

  - 결제일로부터 1~10일 이내 사용	:  총 납입액-(1개월이용료 50%+결제대행수수료+송금수수료)

  - 결제일로부터 10일 이상 사용	  :  총 납입액-(1개월이용료*이용 월 수+결제대행수수료+송금수수료)

(결제수단에 따라 결제대행수수료는 상이할 수 있습니다.)

 

가. 이벤트 기간에 가입되어 추가 이용기간 및 사은품이 지급된 경우의 이용료 환불은 추가 이용기간에 따른 이용료와 사은품의 가격 및 배송료를 추가로 공제합니다.

② 이외 추가 서비스 이용료의 환불은 제21조 ①항의 기준에 의합니다.

※ 단, 제19조(계약해지 및 이용제한) ②항에 따른 계약해지시 사용기간에 관계없이 환불이 불가합니다.

※ 위 사항 외의 별도 해지 수수료는 없습니다.

 

제22조(책임제한)

① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.

② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여 책임을 지지 않습니다.

③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그밖에 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.

④ 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관하여는 책임을 지지 않습니다.

 

제23조(관할법원)

요금 등 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 회사의 본사 소재지를 관할하는 법원을 전속 관할법원으로 합니다.

 

[부칙]

본 약관은 2024년 12월 02일부터 시행합니다.

              </pre>
            </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>
<script>

export default{ 
    components:{},
    data(){
        return{
            sampleData:''
        };
    },
    setup(){},
    created(){},
    mounted(){},
    unmounted(){},
    methods:{}
}
</script>