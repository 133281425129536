<template>
    <div>
      <!-- Modal -->
      <div class="modal fade" id="staticBackdropConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">추천 완료</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              입력하신 회원에 알림톡 전송을 완료했습니다. <br><br>추천한 지인분이 저희 'Noti-X'에 회원 가입 완료되면 <br>알림톡으로 알려 드리겠습니다.<br><br> 저희 서비스를 추천해주셔서 감사합니다.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">닫기</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-4">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-xl-4 col-lg-6 col-md-12">
            <div class="card rounded-3 text-black">
              <div class="row g-0">
                <div class="col-lg-12">
                  <div class="card-body p-md-1 mx-md-4">
                    <div class="text-center mt-3 mb-1 pb-1">
                      <img src="@/assets/images/notix_log_login.png">
                      <h2 class="mt-2 mb-1 pb-1 fw-semibold">추천하기</h2>
                    </div>
                    <form>
                      <div class="form-floating mb-3">
                        <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">이름</label>
                      </div>
                      <div class="form-floating mb-4">
                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">휴대폰 번호</label>
                      </div>
                      
                      <div class="d-flex justify-content-start align-items-center pb-3">
                        위 입력하신 정보의 회원에게  'Noti-X 서비스 회원가입 추천' 카카오 알림톡을 보내드립니다.<br><br>
                        추천받으신 분이 회원으로 가입하시고 10일 이상 이용이 확인 되면 추천인에게 1개월 서비스 무료 이용권을 드립니다.
                      </div>
                      

                      <div class="text-center pt-3 mb-3 pb-1">
                        <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold" 
                        data-bs-toggle="modal" data-bs-target="#staticBackdropConfirm" type="button">추천하기</button>
                      </div>
    
                    </form>
    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <script>
    
    export default{ 
    
        components:{},
        data(){
            return{
                sampleData:''
            };
        },
        setup(){},
        created(){},
        mounted(){},
        unmounted(){},
        methods:{}
    }
    </script>