<template>
<div>
    <div class="container py-4">
    <div class="row d-flex justify-content-center align-items-center">
      <div class="col-xl-4 col-lg-6 col-md-12">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-12">
              <div class="card-body p-md-1 mx-md-4">
                <div class="text-center mt-3 mb-1 pb-1">
                  <img src="@/assets/images/notix_log_login.png">
                  <h2 class="mt-2 mb-1 pb-1 fw-semibold">로그인</h2>
                </div>
                <form>
                  <div class="mb-4">
                    <label for="emailInput" class="form-label d-flex justify-content-start">아이디(EAMIL주소)</label>
                    <input v-model="email" :class="{ 'input-danger': emailHasError }" type="email" class="form-control form-control-lg shadow-sm" ref="emailInput" placeholder="이메일 주소를 정확히 입력해주세요">
                    <p v-show="valid.email"  class="input-error"> 이메일 주소를 정확히 입력해주세요(30자이내). </p>
                  </div>
                  <div class="mb-4">
                    <label for="passwordInput" class="form-label d-flex justify-content-start">패스워드</label>
                    <input v-model="password" :class="{ 'input-danger': passwordHasError }" type="password" class="form-control form-control-lg shadow-sm" ref="passwordInput" placeholder="영문,숫자,특수문자 포함 8~16자">
                    <p v-show="valid.password"  class="input-error"> 패스워드는 영문,숫자,특수문자 포함 8~16자입니다. </p>
                  </div>
                  <div class="text-center pt-1 mb-2 pb-1">
                    <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold" type="button" @click="emailLogin">로그인</button>
                  </div>
                  <hr>
                  <div class="text-center pt-1 mb-4 pb-1">
                    <button class="btn btn-lg d-grid gap-2 col-12 mx-auto fw-semibold" style="background-color: #FEE500;" 
                    type="button" @click="kakaoLogin()">카카오 로그인</button>
                  </div>

                  <div class="d-flex justify-content-between align-items-center pb-4">
                    <small class="text-muted" style="cursor:pointer;" @click="findIdPwd">아이디찾기/비밀번호재설정</small>
                    <button  type="button" class="btn btn-outline-primary" @click="userJoin">이메일 회원가입</button>
                  </div>

                </form>

              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<style scoped>
    .greeting[data-v-7ba5bd90] {
      color: red;
      font-weight: bold;
    }
    .input-error {
    line-height: 16px;
    font-size: 13px;
    color:  red;
    }
    .title-danger {
      color:  red;
    }
    .input-danger {
      border: 1px solid  red !important;
    }

    </style>
<script>
import axios from 'axios';
export default{ 
  components : {
  },
  data(){
      return{
        valid:{
          email: false,
          password: false,
        },
        emailHasError: false,
        passwordHasError: false,

        email:'',
        password:'',
        kakaoNickName:'',
        kakaoEmail:'',
        kakaoPhoneNum:'',

        kakaoHeader : {
        "Authorization": "3f23ea8085bec4afac3cd2bc9f7b729e",
        "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
        }
      };
  },
  setup(){},
  created(){
    if (this.$route.query.code) {
            this.setKakaoToken();
        }
  },
  mounted(){
    
  },
  watch:{

    'email': function() {
      this.checkEmail()
    },
    'password': function() {
      this.checkPassword()
    },

  },
  computed:{

  },
  unmounted(){},
  methods:{
    async getKakaoToken(code) {
      try {
          const data = {
              grant_type: "authorization_code",
              client_id: "da14169a50c69f963a80cd795712f620", //REST API
              redirect_uri: "https://www.notix.co.kr/login",//https://localhost:8080/login   https://www.notix.co.kr/login
              code: code
          };
      
          const queryString = Object.keys(data)
          .map(k => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
          .join("&");
      
          const result = await axios.post(
              "https://kauth.kakao.com/oauth/token",
              queryString,
              { headers: this.kakaoHeader }
          );
          console.log("result",result);
  
          console.log("카카오 토큰", queryString);
          return result;
      } catch (e) {
          return e;
      }
    },

    kakaoLogin() {
      //alert("카카오로 부터 서비스 승인 심사 중입니다.")
      window.Kakao.Auth.authorize({
         redirectUri:"https://www.notix.co.kr/login",//https://localhost:8080/login   https://www.notix.co.kr/login
        //prompt: 'login',
      });
    },
    async setKakaoToken() {
       console.log("카카오 인증 코드", this.$route.query.code);
       const { data } = await this.getKakaoToken(this.$route.query.code);
       // if (data.error) {
       //     alert("카카오톡 로그인 오류입니다.");
       //     this.$router.go();
       //     return;
       // }
       window.Kakao.Auth.setAccessToken(data.access_token);
       //this.$cookies.set("access-token", data.access_token, "1d");
       //this.$cookies.set("refresh-token", data.refresh_token, "1d");
       await this.setUserInfo();
       //this.$router.push({ name: "/kakaologin" });
    },
    async setUserInfo() {
      const res =await window.Kakao.API.request({
          url: "/v2/user/me",
      });
      console.log(res);
      this.kakaoName = res.kakao_account.profile.nickname
      this.kakaoEmail = res.kakao_account.email
      let phoneNum = res.kakao_account.phone_number;
      console.log("kakaoPhoneNum",phoneNum);

      if(phoneNum.startsWith('+82'))
        phoneNum = phoneNum.replace('+82','').replace(' ','');

      phoneNum = phoneNum.replace(/[^0-9]/g, "");  
      console.log("phoneNum",phoneNum);
        
      if(!phoneNum.startsWith('0'))
        phoneNum = '0'+phoneNum

      this.kakaoPhoneNum = phoneNum
      console.log("kakaoPhoneNum2",this.kakaoPhoneNum);

      let user ={user_name:this.kakaoName,login_id:this.kakaoEmail,email:this.kakaoEmail,login_pass:"",phone_num:this.kakaoPhoneNum,login_type:'2',user_type:'1'}

      try{
        const res = await this.$api("/api/kakaoJoin",{param:[user]});
        console.log("res",res);
        console.log("res.code",res.code);
        if (res.code == 200){
          console.log("res.code == 200");
          this.$store.commit("tokens",{
              'accessToken': res.data.accessToken,
              'refreshToken': res.data.refreshToken,
              'user': res.data.user
          });
          console.log("this.$store.state.tokens",this.$store.state.tokens);
          if(res.data.user.last_login_type != 2){
            this.$swal.fire({
              title: '로그인 완료',
              html: '로그인이 완료되었습니다.<br/>홈으로 이동합니다.',
              icon: 'success',
              confirmButtonText: '확인'
            }).then(async (result) => {
              console.log(result);
              this.$router.push({path:'/today'});

            });
          }else{ 
            this.$swal.fire({
              title: '로그인 완료',
              html: '로그인이 완료되었습니다.<br/>홈으로 이동합니다.',
              icon: 'success',
              confirmButtonText: '확인'
            }).then(async (result) => {
              console.log(result);
              this.$router.push({path:'/today'});

            });
        }
        }else if(res.code == 401){
          this.$swal.fire({
            title:'로그인 실패 ',
            html: res.message,
            icon: 'error',
            confirmButtonText: '확인'
          });
        }else{
          this.$swal.fire({
            title:'서버 오류 ',
            html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
            icon: 'error',
            confirmButtonText: '확인'
          });

        }

      }catch(err){
        console.log('err',err)
          this.$swal.fire({
            title:'서버 오류 ',
            html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
            icon: 'error',
            confirmButtonText: '확인'
          });

      }
      // this.$store.commit("setUser", userInfo);
    },

    userJoin(){
      this.$router.push({path:'/join'});
    },
    findIdPwd(){
      this.$router.push({path:'/findIdPwd'});
    },
    checkEmail() {
      // 이메일 형식 검사
      const validateEmail = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/

      if (!validateEmail.test(this.email) || !this.email || !(this.email.length < 30)) {
        this.valid.email = true
        this.emailHasError = true
        return
      }else{
        this.valid.email = false
        this.emailHasError = false
        return
      }
    
      
    },
    checkPassword() {
      // 비밀번호 형식 검사(영문, 숫자, 특수문자)
      const validatePassword = /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[$`~!@$!%*#^?&\\(\\)\-_=+]).{8,16}$/

      if (!validatePassword.test(this.password) || !this.password) {
        this.valid.password = true
        this.passwordHasError = true
        return
      }else{
        this.valid.password = false
        this.passwordHasError = false
        return
      }
     
    },
    
    async emailLogin(){
      
      if ( this.email.length < 3 || this.emailHasError) {
        this.$refs.emailInput.focus();
        this.$swal.fire({
          title:'로그인 오류',
          text: '이메일 아이디을 확인하시기 바랍니다.',
          icon: 'error',
          confirmButtonText: '확인'
        });
        return
      }

      if ( this.password.length < 8 || this.passwordHasError) {
        this.$refs.passwordInput.focus();
        this.$swal.fire({
          title:'로그인 오류',
          text: '패스워드를 확인하시기 바랍니다.',
          icon: 'error',
          confirmButtonText: '확인'
        });
        return
      }
      const user ={email:this.email,login_pass:this.password}
      try{
        const res = await this.$api("/api/emailLogin",{param:[user]});
        console.log("res",res);
        console.log("res.code",res.code);
        if (res.code == 200){
          console.log("res.code == 200");
          this.$store.commit("tokens",{
              'accessToken': res.data.accessToken,
              'refreshToken': res.data.refreshToken,
              'user': res.data.user
            });
            console.log("this.$store.state.tokens",this.$store.state.tokens)
          this.$swal.fire({
            title: '로그인 완료',
            html: '로그인이 완료되었습니다.<br/>홈으로 이동합니다.',
            icon: 'success',
            confirmButtonText: '확인'
          }).then(async (result) => {
            console.log(result);
            this.$router.push({path:'/today'});

          });
        }else if(res.code == 401){
          this.$swal.fire({
            title:'로그인 실패 ',
            html: res.message,
            icon: 'error',
            confirmButtonText: '확인'
          });
        }else{
          this.$swal.fire({
            title:'서버 오류 ',
            html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
            icon: 'error',
            confirmButtonText: '확인'
          });

        }

      }catch(err){
        console.log('err',err)
          this.$swal.fire({
            title:'서버 오류 ',
            html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
            icon: 'error',
            confirmButtonText: '확인'
          });

      }
      
    }
  }
}
</script>