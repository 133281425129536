<template>
    <div class="container py-4">
            <div class="row d-flex justify-content-center align-items-center">
              <div class="col-xl-4 col-lg-6">
                <div class="card rounded-3 text-black shadow-sm">
                  <div class="row g-0">
                    <div class="col-lg-12">
                      <div class="card-body p-md-1 mx-md-4 " >
                        <div class="text-center mt-3 mb-1 pb-1">
                          <img src="@/assets/images/notix_log_login.png">
                          <h2 class="mt-2 mb-1 pb-1 fw-semibold">개인정보 처리방침</h2>
                        </div>
                        <div class="modal-body">
                  <pre style="text-align:left; white-space: pre-wrap;  word-break: break-all;  overflow: auto;">
신나는 주식회사는(이하 ‘회사’는) 고객님의 개인정보를 중요시하며, 개인정보 보호를 위해 다음과 같은 정책을 시행하고 있습니다. 본 정책은 ‘정보통신망 이용촉진 및 정보보호에 관한 법률’, ‘통신비밀보호법’, ‘개인정보보호법’, ‘위치정보의 보호 및 이용등에 관한 법률’ 등 정보통신 서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 이용자 권익보호에 최선을 다하고 있습니다. 회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.

 

1. 수집하는 개인정보의 항목 및 수집방법

1) 수집하는 개인정보의 항목

① 회사는 회원가입, 카카오 알림톡과 SMS 및 전자우편으로 정보 제공 등 각종 서비스의 제공을 위해 최초 회원가입 당시 아래와 같은 개인정보를 수집하고 있습니다.

● 기업회원 가입시

- 필수항목 : 회사명, 업태, 종목, 회사전화번호, 팩스번호, 사업자등록번호, 회사주소, 대표자명, 로그인ID, 비밀번호

- 선택항목 : 세부종목, 담당자명, 이메일주소, 휴대폰번호, 우편물 수령 주소

● 개인회원 가입시

- 필수항목 : 로그인ID, 비밀번호, 이름, 전화번호

- 선택항목 : 회사명, 사업자등록번호, 대표자명, 회사주소, 업태, 종목, 세부종목, 담당자명, 이메일주소, 휴대폰번호, 우편물 수령 주소

② 서비스 이용과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수 있습니다.

- IP Address, 쿠키, 방문 일시, OS종류, 브라우져 종류, 서비스 이용 기록, 불량 이용 기록,

- 모바일 서비스 이용 시 : 단말기 모델, 이동통신사 정보, 하드웨어ID, 단말기OS종류, 모델종류, 모바일 접속지역

③ 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한해서만 아래와 같은 정보들이 수집될 수 있습니다.

- 개인정보 추가 수집에 대해 동의를 받는 경우

④ 유료 서비스 이용 과정에서 아래와 같은 결제 정보들이 수집될 수 있습니다.

- 신용카드 결제시 : 카드사명, 카드번호 등

- 휴대전화 결제시 : 휴대전화번호, 통신사, 결제승인번호, 이메일 주소 등

- 계좌이체시 : 은행명, 계좌번호 등

- 상품권 이용시 : 상품권 번호

- 환불시 : 환불계좌정보(은행명, 계좌번호, 예금주명)

2) 개인정보 수집 방법

① 홈페이지(회원가입, 게시판), 서면양식을 통한 회원가입

② 회원정보수정, 제휴사로부터의 제공, 이벤트응모

③ 상담(전화, 이메일), 고객센터, Q&A, 배송요청, 비회원구매

④ 생성정보 수집 툴을 통한 수집

 

2. 개인정보의 수집 및 이용목적

회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 이용자가 제공한 모든 정보는 하기 목적에 필요한 용도 이외로는 사용되지 않으며, 이용 목적이 변경될 시에는 사전동의를 구할 것입니다.

1) 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산

① 컨텐츠 제공, 특정 맞춤 서비스 제공

② 물품배송 또는 청구서 등 발송

③ 금융거래 본인 인증 및 금융 서비스

④ 구매 및 요금 결제, 요금추심 등

2) 회원관리

① 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인 식별

② 불량회원의 부정 이용방지와 비인가 사용방지

③ 가입의사 확인, 가입 및 가입횟수 제한

④ 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부, 추후 법정 대리인 본인확인

⑤ 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달

3) 신규 서비스 개발 및 마케팅·광고에의 활용

① 신규 서비스 개발 및 맞춤 서비스 제공

② 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인

③ 이벤트 및 광고성 정보 제공 및 참여기회 제공

④ 접속빈도 파악 등에 대한 통계

 

3. 개인정보의 제공

수집된 개인정보는 아래의 내용에 따라 회사에 제공하게 됩니다. 이용자는 제공을 거부하실 수 있는 권리가 있으며, 거부 시에는 제공하는 혜택에 제한이 있을 수 있습니다.

1) 이용자들이 사전에 공개에 동의한 경우

- 회사 및 회사의 제휴사 이벤트 및 마케팅 정책에 의한 홍보를 위해 최소한의 기본정보 제공

2) 서비스의 제공에 관한 계약의 이행을 위하여 필요한 개인정보로서 경제적/기술적인 사유로 통상의 동의를 받는 것이 현저히 곤란한 경우

3) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

 

4. 개인정보의 제3자 제공

회사는 개인정보를 "2. 개인정보 수집 및 이용목적"에서 고지한 범위내에서 사용하며, 정보주체의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.

1) 입찰/평가위원 모집 정보 제공 서비스, 신청서 작성 서비스, 통계 분석 서비스 제공을 위한 운영 및 고객상담

- 개인정보를 제공받는 자 : 신나는 주식회사 

- 개인정보를 제공받는 자의 개인정보 이용 목적 : 입찰/평가위원 모집 정보 제공 서비스, 신청서 작성 서비스, 통계 분석 서비스 제공을 위한 운영 및 고객상담

- 제공하는 개인정보의 항목 : ID, 이름, 전화번호, 휴대전화번호, 주소, 이메일, 위원 모집 신청서 작성을 위해 고객이 제공한 모든 정보

- 개인정보를 제공받는 자의 개인정보 보유 및 이용기간 : 회원 탈퇴시 까지


2) 정보주체가 사전에 동의한 경우

3) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우

4) 제3자 제공 업무 발생 시, 필요시점에 고객에게 별도로 알리고 동의받는 절차가 진행됩니다.

 

5. 개인정보의 보유 및 이용기간

사용자 개인정보는 회사에서 제공하는 서비스를 이용하는 동안 계속적으로 보유하며 회사의 다양한 서비스 제공을 위하여 이용합니다. 사용자의 개인정보는 사용자가 직접 열람/정정이 가능하며, 가입해지를 요청한 경우에는 재가입 여부 판단을 목적으로 최소정보(아이디/고객명/사업자등록번호)만을 보존하며, 그 외 모든 정보는 재생이 불가한 형태로 영구 삭제됩니다. 이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체없이 파기합니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.

1) 회사 내부 방침에 의한 정보보유 사유

- 보존 항목 : 로그인ID, 상호명, 사업자등록번호, 대표이름(개인이름), 전화번호, 회사주소, 이메일주소

- 보존 근거 : 서비스 이용의 혼선 및 부정이용 방지, 불법적 사용자에 대한 관련 기관 수사협조

- 보존 기간 : 탈퇴 후 1개월

2) 관련법령에 의한 정보보유 사유

상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 관계법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.

① 계약 또는 청약철회 등에 관한 기록

- 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

② 대금결제 및 재화 등의 공급에 관한 기록

- 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

③ 소비자의 불만 또는 분쟁처리에 관한 기록

- 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

④ 본인 확인에 관한 기록

- 보존 근거 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

⑤ 웹사이트 방문기록

- 보존 근거 : 통신비밀보호법

- 보존 기간 : 탈퇴 후 3개월

⑥ 개인위치정보에 관한 기록

- 보존 근거 : 위치정보의 보호 및 이용 등에 관한 법률

- 보존 기간 : 탈퇴 후 3개월

 

6. 개인정보의 파기절차 및 방법

이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.

1) 파기절차

① 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 보관장소) 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)일정 기간 저장된 후 파기됩니다.

② 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.

③ 보존기간이 경과한 정보 관련 문서(대외비는 부서보안관리자, 비밀은 정보보호관리자)는 해당관리자의 승인을 받아 폐기합니다.

2) 파기방법

① 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.

② 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법(재사용 시 덮어 쓰기, 로우(low) 포맷 등)을 사용하여 삭제합니다.

 

7. 이용자 및 법정대리인의 권리와 그 행사방법

1) 이용자 및 법정대리인은 회사에 대해 언제든지 다음 각 호의 개인정보 보호 관련 권리를 행사할 수 있습니다.

① 개인정보 열람요구

② 오류 등이 있을 경우 정정 요구

③ 삭제요구

④ 처리정지 요구

2) 제1항에 따른 권리 행사는 회사 고객센터 내 개인정보(열람, 정정·삭제, 처리정지) 요구서에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.

3) 이용자 및 법정대리인이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에 회사는 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나 제공하지 않습니다.

4) 제1항에 따른 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다.

이 경우 위임장을 작성하여 제출하셔야 합니다.

5) 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "5. 개인정보의 보유 및 이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.

 

8. 개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항

회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.

 

1) 쿠키의 사용 목적

회사는 회원인증을 위하여 쿠키(Cookie)방식을 이용하고 있습니다. 이는 로그아웃(Logout)시 자동으로 컴퓨터에 저장되지 않고 삭제되도록 되어 있으므로, 공공장소나 타인이 사용할 수 있는 컴퓨터를 사용하실 경우에는 로그인(Login)후 서비스 이용이 끝나시면 반드시 로그아웃(Logout)해 주시기 바랍니다.

2) 쿠키의 설치·운영 및 거부

① 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.

② 설정방법 예(인터넷 익스플로어 경우 : 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보)

③ 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.

 

9. 개인정보의 기술적/관리적 보호 대책

회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다.

1) 비밀번호 암호화

회원아이디(ID)의 비밀번호는 암호화 되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.

2) 해킹 등에 대비한 대책

회사는 서버 및 네트워크 관련 첨단 보안시스템을 갖추고 있습니다. 자체 방화벽 및 침입탐지 시스템, 침입방지 시스템을 갖추고 고객님의 개인정보 보호를 위해 최선의 방법을 취하고 있습니다. 또한 전자 결제에 대해서는 SSL(Secure Socket Layer)방식으로 보안 시스템을 탑재하고 있습니다. 또한 고객님이 개인정보를 열람할 수 있는 시스템에는 2차 암호 체제를 갖추고 외부 네트워크로부터 철저하게 격리시켜 외부 침입 및 내부 침입에 대응하고 있습니다.

3) 취급 직원의 최소화 및 교육

회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보취급방침의 준수를 항상 강조하고 있습니다.

4) 개인정보보호전담기구의 운영

사내 개인정보보호전담기구 등을 통하여 회사의 개인정보취급방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.

 

10. 개인정보보호최고책임자 및 담당자의 연락처

귀하께서는 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호최고책임자 혹은 담당부서로 신고하실 수 있습니다.

회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.

- 개인정보보호최고책임자 : 김연식 대표, 1577-7338, kim@sinnaneun.co.kr

- 개인정보보호관리자 : 송칠용 COO, 1577-7338, cysong3@sinnaneun.co.kr


기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.

- 개인정보침해신고센터 ( http://www.1336.or.kr / (국번없이) 1336 )

- 개인정보분쟁조정위원회 ( http:// http://www.kopico.or.kr / 02-405-5150 )

- 대검찰청 사이버범죄수사단 ( http://cybercid.spo.go.kr / 02-3480-2480 )

- 경찰청 사이버테러대응센터 ( http://www.ctrc.go.kr / 02-3150-2659 )

 

11. 고지의 의무

현 개인정보취급방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.

- 개인정보 취급방침 시행일자: 2024.12.02.
    
                  </pre>
                </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </template>
<script>

export default{ 
    components:{},
    data(){
        return{
            sampleData:''
        };
    },
    setup(){},
    created(){},
    mounted(){},
    unmounted(){},
    methods:{}
}
</script>