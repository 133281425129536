
 
<template>
  <main>
    <div v-for="(item, index) in items" :key="index">
      <!-- 아이템 렌더링 내용 -->
      {{ item }}
    </div>
    <Observer @show="loadItems"></Observer>
  </main>
</template>
 
<script>
import Observer from '../components/ObserverView.vue'
export default {
  components:{
    Observer
  },
  data() {
    return {
      page:1,
      items: []
    }
  },
  mounted() {
    this.loadItems()
  },
  methods: {
    loadItems() {
      for (let i = 0; i < 20; i++) {
        this.items.push(`아이템 ${this.items.length + 1}`);
      }
    },
  }
}
 
</script>