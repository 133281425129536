<template>
    <div>
      <!-- Modal -->
      <div class="modal fade" id="staticBackdropIdConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">아이디 찾기</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              등록된 아이디를 휴대폰 SMS 메세지로 전송 완료했습니다. <br><br>로그인 창에서 등록 아이디와 패스워드로 <br>로그인 하시면 서비스를 모두 사용하실 수 있습니다.<br><br> 좋은 서비스가 되도록 항상 노력하겠습니다. 감사합니다.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">로그인으로 가기</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
      <div class="modal fade" id="staticBackdropPwdConfirm" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">비밀번호 재설정</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              재설정된 비밀번호를 휴대폰 SMS 메세지로 전송 완료했습니다. <br><br>로그인 창에서 등록 이메일 아이디와 재설정패스워드로 <br>로그인 하시면 서비스를 모두 사용하실 수 있습니다.<br><br> 내정보에서 비밀번호를 변경하시기 바랍니다. 감사합니다.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal">로그인으로 가기</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container py-4">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-xl-4 col-lg-6 col-md-12">
            <div class="card rounded-3 text-black">
              <div class="row g-0">
                <div class="col-lg-12">
                  <div class="card-body p-md-1 mx-md-4">
                    <div class="text-center mt-3 mb-1 pb-3">
                      <img src="@/assets/images/notix_log_login.png">
                    </div>
                    <form>
                    
                      <div class="mb-3">
                        <label for="nameInput" class="form-label d-flex justify-content-start">이름</label>
                        <input v-model="name" :class="{ 'input-danger': nameHasError }" type="text" class="form-control form-control-lg shadow-sm" ref="nameInput" placeholder="이름을 입력해주세요(2~10자이내)">
                        <p v-show="valid.name"  class="input-error"> 이름을 입력해주세요(2~10자이내). </p>
                      </div>
                      <div class="mb-3">
                        <label for="phoneNumIdInput" class="form-label d-flex justify-content-start">등록 휴대폰 번호</label>
                        <input v-model="phoneNumId" type="text" class="form-control form-control-lg shadow-sm" ref="phoneNumInput" placeholder="000-0000-0000">
                        <p v-show="valid.phoneNumId"  class="input-error"> 휴대폰 번호(000-0000-0000)를 입력하시기 바랍니다.. </p>
                      </div>
                      <div class="text-center pt-1 mb-1 pb-1">
                        <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold" 
                        type="button" @keyup.enter="findId" @click="findId">아이디 찾기</button>
                      </div>

                      <!-- <div class="text-start mt-5 mb-1 pb-1">
                      <h3 class="mt-2 mb-1 pb-1 fw-semibold">비밀번호 재설정</h3>
                      </div> -->
                    
                      <div class="pt-5 mb-3">
                        <label for="emailInput" class="form-label d-flex justify-content-start">아이디(EAMIL주소)</label>
                        <input v-model="email" :class="{ 'input-danger': emailHasError }" type="email" class="form-control form-control-lg shadow-sm" ref="emailInput" placeholder="이메일 주소를 정확히 입력해주세요">
                        <p v-show="valid.email"  class="input-error"> 이메일 주소를 정확히 입력해주세요(30자이내). </p>
                      </div>
                      <div class="mb-3">
                        <label for="phoneNumPwdInput" class="form-label d-flex justify-content-start">등록 휴대폰 번호</label>
                        <input v-model="phoneNumPwd" type="text" class="form-control form-control-lg shadow-sm" ref="phoneNumInput" placeholder="000-0000-0000">
                        <p v-show="valid.phoneNumPwd"  class="input-error"> 휴대폰 번호(000-0000-0000)를 입력하시기 바랍니다.. </p>
                      </div>
                      <div class="text-center pt-1 mb-1 pb-1">
                        <button class="btn btn-lg btn-primary d-grid gap-2 col-12 mx-auto fw-semibold" 
                        type="button"  @keyup.enter="resetPwd" @click="resetPwd">비밀번호 재설정</button>
                      </div>
    
                    </form>
    
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <script>
    
    export default{ 
    
        components:{},
        data(){
            return{
              valid:{
                name:false,
                email: false,
                phoneNumId:false,
                phoneNumPwd:false,
              },
              nameHasError: false,
              emailHasError: false,
              phoneNumIdHasError: false,
              phoneNumPwdHasError: false,

              name:'',
              email:'',
              phoneNumId:'',
              phoneNumPwd:'',

            };

        },
        setup(){},
        created(){},
        mounted(){},
        watch:{
          'name': function() {
            this.checkName()
          },
          'email': function() {
            this.checkEmail()
          },
          'phoneNumId': function() {
            this.checkPhoneNumId()
          },
          'phoneNumPwd': function() {
            this.checkPhoneNumPwd()
          },
        },
        unmounted(){},
        methods:{
          checkName() {
            // 이메일 형식 검사
            const validateName = /^[A-Za-z가-힣]+/
              if (!validateName.test(this.name) || !this.name || this.name.length < 2 ) {
                this.valid.name = true
                this.nameHasError = true
                return
              }else{
                this.valid.name = false
                this.nameHasError = false
                return
              }  
            
          },
          async checkEmail() {
            // 이메일 형식 검사
            const validateEmail = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/

              if (!validateEmail.test(this.email) || !this.email || !(this.email.length < 30)) {
                this.valid.email = true
                this.emailHasError = true
                return
              }else{
                let userData = await this.$api("/api/checkLoginId",{param:[this.email]});
                if ('error' in userData){
                  this.$swal.fire({
                      title:'서버 오류 ',
                      html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
                      icon: 'error',
                      confirmButtonText: '확인'
                    });
                    return
                }

                if (userData.length == 1 ){
                  console.log(userData[0].user_name,userData[0].login_type);
                  if(userData[0].login_type != null){
                    this.$swal.fire({
                      title:'회원가입 오류 ',
                      html: '회원가입된 이메일 아이디입니다.<br/>다른 이메일 아이디를 입력하시기 바랍니다.',
                      icon: 'error',
                      confirmButtonText: '확인'
                    });
                    this.email = '';
                    return
                  }else{
                    //기존 베타 서비스 사용자
                    this.userJoinUpdate = true;
                  }
                }else{  
                  this.valid.email = false
                  this.emailHasError = false
                  return
                }
              }
          
            
          },
          
          checkPhoneNumId() {
            // 비밀번호 형식 검사(영문, 숫자, 특수문자)
            const validatePhoneNumId =/^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

            if (!validatePhoneNumId.test(this.phoneNumId) || !this.phoneNumId) {
              this.valid.phoneNumId = true
              this.phoneNumIdHasError = true
              return
            }else{
              this.valid.phoneNumId = false
              this.phoneNumIdHasError = false
              return
            }
          },
          checkPhoneNumPwd() {
            // 비밀번호 형식 검사(영문, 숫자, 특수문자)
            const validatePhoneNumPwd =/^(01[016789]{1})-?[0-9]{3,4}-?[0-9]{4}$/;

            if (!validatePhoneNumPwd.test(this.phoneNumPwd) || !this.phoneNumPwd) {
              this.valid.phoneNumPwd = true
              this.phoneNumPwdHasError = true
              return
            }else{
              this.valid.phoneNumPwd = false
              this.phoneNumPwdHasError = false
              return
            }
          },
          async findId(){
            
            if ( this.name == '' || this.name.length < 2 || this.nameHasError) {
              this.$refs.nameInput.focus();
              this.$swal.fire({
                title:'아이디찾기',
                html: '이름을 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }
            
            if ( this.phoneNum.length < 5 || this.phoneNumHasError) {
              this.$refs.phoneNumInput.focus();
              this.$swal.fire({
                title:'아이디 찾기',
                html: '휴대폰 번호를 다시 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }
            let confirm = ''
            let user ={user_name:this.name,login_id:this.email,email:this.email,login_pass:this.password,phone_num:this.phoneNum.replace(/[^0-9]/g, ""),login_type:'1',user_type:'1'}
            if(this.userJoinUpdate){
              confirm = await this.$api("/api/userjoinUpdate",{param:[user]});
            }else{
              
              confirm = await this.$api("/api/userJoin",{param:[user]});
            }

            if ('error' in confirm){
                console.log('error',confirm)
                this.$swal.fire({
                    title:'서버 오류 ',
                    html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
            }else if ('kakao' in confirm){
                console.log('error',confirm)
                this.$swal.fire({
                    title:'아이디 잧기 ',
                    html: '카카오 로그인으로 회원 등록하셨습니다 .<br/>카카오로 로그인 하시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
            }            else{
              this.$swal.fire({
                title: '아이디 잧기',
                html: '휴대폰 메세지로 아이디 정보를 전송하였습니다. <br/> 확인 바랍니다.',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(async (result) => {
                console.log(result);
                this.$router.push({path:'/login'});

              });
            } 
          },
          async resetPwd(){
            
            if ( this.email.length < 3 || this.emailHasError) {
              this.$refs.emailInput.focus();
              this.$swal.fire({
                title:'패스워드 재설정',
                html: '이메일 아이디을 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }
            if ( this.phoneNum.length < 5 || this.phoneNumHasError) {
              this.$refs.phoneNumInput.focus();
              this.$swal.fire({
                title:'패스워드 재설정',
                html: '휴대폰 번호를 다시 확인하시기 바랍니다.',
                icon: 'error',
                confirmButtonText: '확인'
              });
              return
            }

            let confirm = ''
            let user ={user_name:this.name,login_id:this.email,email:this.email,login_pass:this.password,phone_num:this.phoneNum.replace(/[^0-9]/g, ""),login_type:'1',user_type:'1'}
            if(this.userJoinUpdate){
              confirm = await this.$api("/api/userjoinUpdate",{param:[user]});
            }else{
              
              confirm = await this.$api("/api/userJoin",{param:[user]});
            }

            if ('error' in confirm){
                console.log('error',confirm)
                this.$swal.fire({
                    title:'서버 오류 ',
                    html: '서버에서 데이터를 가져오지 못했습니다.<br/>관리자에게 연락해주시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
                }else if ('kakao' in confirm){
                console.log('error',confirm)
                this.$swal.fire({
                    title:'패스워드 재설정 ',
                    html: '카카오 로그인으로 회원 등록하셨습니다 .<br/>카카오로 로그인 하시기 바랍니다.',
                    icon: 'error',
                    confirmButtonText: '확인'
                  });
                  return
            }            else{
              this.$swal.fire({
                title: '패스워드 재설정',
                html: '휴대폰 메세지로 재설정된 패스워드를 전송하였습니다. <br/> 확인 바랍니다.',
                icon: 'success',
                confirmButtonText: '확인'
              }).then(async (result) => {
                console.log(result);
                this.$router.push({path:'/login'});

              });
            } 
          }
        }
    }
    </script>