<template>
    <div class="vl-parent">
      <vue-loading
        v-model:active="isLoading"
        :can-cancel="true"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
      />
  
      <label><input type="checkbox" v-model="fullPage" />Full page?</label>
      <button class="btn btn-primary me-2" @click.prevent="doAjax">
        fetch Data
      </button>
      <button class="btn btn-danger" @click.prevent="onCancel">Cancel</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false,
        fullPage: true
      }
    },
    methods: {
      doAjax() {
        this.isLoading = true

        let phoneNum ='+82 10-8752-1037';

        if(phoneNum.startsWith('+82'))
          phoneNum = phoneNum.replace('+82','').replace(' ','');

        phoneNum = phoneNum.replace(/[^0-9]/g, "");  
        console.log("phoneNum",phoneNum);
        
        if(!phoneNum.startsWith('0'))
          phoneNum = '0'+phoneNum

        
        console.log("phoneNum2",phoneNum);

        // simulate AJAX
        setTimeout(() => {
          this.isLoading = false
        }, 5000)
      },
      onCancel() {
        this.isLoading = false
        console.log('User cancelled the loader.')
      }
    }
  }
  </script>